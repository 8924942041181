
const HexToRgb = (hex, onlyConvert = false) => {
	const hexConvert = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
	try {

		let hexConvertResult = {
			r: parseInt(hexConvert[1], 16),
			g: parseInt(hexConvert[2], 16),
			b: parseInt(hexConvert[3], 16)
		};
		let result = "#000000";
		if (onlyConvert === true) {
			result = hexConvertResult;
		} else {


			if ((hexConvertResult.r * 0.299 + hexConvertResult.g * 0.587 + hexConvertResult.b * 0.114) > 186) {
				result = "#000000";
			} else {
				result = "#ffffff";
			}

		}

		return result;
	} catch (error) {
		return error;
	}

};

export default HexToRgb