import { Dialog } from "@material-ui/core";
import React, { useState } from "react";

import { BlockPicker } from "react-color";

const ColorPicker = ({ onChange, currentColor, handleClose, open, noDialog }) => {
  const [selectedColor, setSelectedColor] = useState(currentColor || "#00a857");
  let colors = [
    "#BEDADC",
    "#C1E1C5",
    "#b9f6ca",
    "#69f0ae",
    "#00e676",
    "#00c853",
    "#00a857",
    "#008B02",
    "#ccff90",
    "#b2ff59",
    "#76ff03",
    "#64dd17",
    "#68BC00",
    "#f4ff81",
    "#eeff41",
    "#DBDF00",
    "#B0BC00",
    "#808900",
    "#A4DD00",
    "#c6ff00",
    "#aeea00",
    "#ffff8d",
    "#ffff00",
    "#ffea00",
    "#FCDC00",
    "#ffd600",
    "#FEF3BD",
    "#ffe57f",
    "#ffd740",
    "#FCCB00",
    "#ffc400",
    "#FCC400",
    "#ffab00",
    "#ffd180",
    "#ffab40",
    "#FB9E00",
    "#FE9200",
    "#ff9100",
    "#E27300",
    "#ff6d00",
    "#C45100",
    "#ff9e80",
    "#ff6e40",
    "#ff3d00",
    "#DB3E00",
    "#dd2c00",
    "#FAD0C3",
    "#EB9694",
    "#ff8a80",
    "#ff5252",
    "#ff1744",
    "#D33115",
    "#d50000",
    "#B80000",
    "#9F0500",
    "#ff80ab",
    "#ff4081",
    "#f50057",
    "#c51162",
    "#D4C4FB",
    "#FDA1FF",
    "#ea80fc",
    "#e040fb",
    "#d500f9",
    "#FA28FF",
    "#653294",
    "#aa00ff",
    "#AB149E",
    "#8c9eff",
    "#AEA1FF",
    "#b388ff",
    "#7c4dff",
    "#7B64FF",
    "#651fff",
    "#5300EB",
    "#6200ea",
    "#1273DE",
    "#536dfe",
    "#004DCF",
    "#3d5afe",
    "#304ffe",
    "#82b1ff",
    "#448aff",
    "#2979ff",
    "#2962ff",
    "#80d8ff",
    "#73D8FF",
    "#40c4ff",
    "#00b0ff",
    "#0091ea",
    "#006B76",
    "#194D33",
    "#0C797D",
    "#0062B1",
    "#16A5A5",
    "#009CE0",
    "#84ffff",
    "#18ffff",
    "#00e5ff",
    "#68CCCA",
    "#00b8d4",
    "#a7ffeb",
    "#64ffda",
    "#1de9b6",
    "#00bfa5",
    "#d7ccc8",
    "#bcaaa4",
    "#8d6e63",
    "#5d4037",
    "#FFFFFF",
    "#eeeeee",
    "#cccccc",
    "#bdbdbd",
    "#B3B3B3",
    "#999999",
    "#808080",
    "#666666",
    "#4D4D4D",
    "#616161",
    "#333333",
    "#2C3E50",
    "#cfd8dc",
    "#C4DEF6",
    "#BED3F3",
    "#b0bec5",
    "#78909c",
    "#455a64",
  ];

  const handleChange = (color) => {
    setSelectedColor(color.hex);
    handleClose();
  };

  return (
    !noDialog ? (
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}
        maxWidth="xs"
        paperFullWidth
      >
        <BlockPicker
          width={"100%"}
          triangle="hide"
          color={selectedColor}
          colors={colors}
          onChange={handleChange}
          onChangeComplete={(color) => onChange(color.hex)}
        />
      </Dialog>
    ) : (
      <BlockPicker
        width={"100%"}
        triangle="hide"
        color={selectedColor}
        colors={colors}
        onChange={handleChange}
        onChangeComplete={(color) => onChange(color.hex)}
      />
    )
  );
};

export default ColorPicker;
