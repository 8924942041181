const PriceBRL = ({ value, cifrao=true }) => {
	let valueString = `${value}`;
	valueString = valueString.replace(/\D/g, "");
	var tmp = valueString.replace(/([0-9]{2})$/g, ",$1");
	if( tmp.length > 6 )
			tmp = tmp.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
	
	if (cifrao) tmp = "R$ "+tmp;

	return tmp;


	// const valueString = `${value}`;
	// const valueRemoved = valueString.replace(/\D/g, "");
	// const sizeSlice = valueRemoved.length - 2;
	// let newValue;
	// if (sizeSlice === 0) {
	//   newValue = [valueRemoved.slice(sizeSlice), ',', "00"].join('');
	// } else {
	//   newValue = [valueRemoved.slice(0, sizeSlice), ',', valueRemoved.slice(sizeSlice)].join('');
	// }
	// if (cifrao) newValue = "R$ "+newValue;
	// return newValue;

};

export default PriceBRL;
