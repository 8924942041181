import React, { useState, useEffect, useReducer } from "react";
import openSocket from "../../services/socket-io";


import {
  Hidden,
  Button,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import { Edit, DeleteOutline, CheckCircle, Search, AddBox } from "@material-ui/icons";

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";

import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import PaymentMethodModal from "../../components/PaymentMethodModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import { toast } from "react-toastify";
import toastError from "../../errors/toastError";

const reducer = (state, action) => {
  if (action.type === "LOAD_PAIMENTMETHODS") {
    const paymentMethod = action.payload;
    const newPaymentMethods = [];

    paymentMethod.forEach((paymentMethod) => {
      const paymentMethodIndex = state.findIndex((q) => q.id === paymentMethod.id);
      if (paymentMethodIndex !== -1) {
        state[paymentMethodIndex] = paymentMethod;
      } else {
        newPaymentMethods.push(paymentMethod);
      }
    });

    return [...state, ...newPaymentMethods];
  }

  if (action.type === "UPDATE_PAIMENTMETHODS") {
    const paymentMethod = action.payload;
    const paymentMethodIndex = state.findIndex((q) => q.id === paymentMethod.id);

    if (paymentMethodIndex !== -1) {
      state[paymentMethodIndex] = paymentMethod;
      return [...state];
    } else {
      return [paymentMethod, ...state];
    }
  }

  if (action.type === "DELETE_PAIMENTMETHODS") {
    const paymentMethodId = action.payload;

    const paymentMethodIndex = state.findIndex((q) => q.id === paymentMethodId);
    if (paymentMethodIndex !== -1) {
      state.splice(paymentMethodIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
}));


const PaymentMethods = () => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchParam, setSearchParam] = useState("");
  const [paymentMethod, dispatch] = useReducer(reducer, []);
  const [selectedPaymentMethods, setSelectedPaymentMethods] = useState(null);
  const [paymentMethodModalOpen, setPaymentMethodModalOpen] = useState(false);
  const [deletingPaymentMethods, setDeletingPaymentMethods] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchPaymentMethods = async () => {
        try {
          const { data } = await api.get("/paymentMethod/", {
            params: { searchParam, pageNumber },
          });
          dispatch({ type: "LOAD_PAIMENTMETHODS", payload: data.paymentMethods });
          setHasMore(data.hasMore);
          setLoading(false);
        } catch (err) {
          toastError(err);
        }
      };
      fetchPaymentMethods();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber]);

  useEffect(() => {


    const socket = openSocket();

    socket.on("paymentMethod", (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_PAIMENTMETHODS", payload: data.paymentMethod });
      }

      if (data.action === "delete") {
        dispatch({
          type: "DELETE_PAIMENTMETHODS",
          payload: +data.paymentMethodId,
        });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };

  const handleOpenPaymentMethodModal = () => {
    setSelectedPaymentMethods(null);
    setPaymentMethodModalOpen(true);
  };

  const handleClosePaymentMethodModal = () => {
    setSelectedPaymentMethods(null);
    setPaymentMethodModalOpen(false);
  };

  const handleEditPaymentMethods = (paymentMethod) => {
    setSelectedPaymentMethods(paymentMethod);
    setPaymentMethodModalOpen(true);
  };

  const handleDeletePaymentMethods = async (paymentMethodId) => {
    try {
      await api.delete(`/paymentMethod/${paymentMethodId}`);
      toast.success(i18n.t("global.toasts.deleted"));
    } catch (err) {
      toastError(err);
    }
    setDeletingPaymentMethods(null);
    setSearchParam("");
    setPageNumber(1);
  };

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  return (
    <MainContainer>
      <ConfirmationModal
        title={
          deletingPaymentMethods &&
          `${i18n.t("global.deleteTitle")} ${deletingPaymentMethods.shortcut
          }?`
        }
        open={confirmModalOpen}
        onClose={setConfirmModalOpen}
        onConfirm={() => handleDeletePaymentMethods(deletingPaymentMethods.id)}
      >
        {i18n.t("global.deleteMessage")}
      </ConfirmationModal>
      <PaymentMethodModal
        open={paymentMethodModalOpen}
        onClose={handleClosePaymentMethodModal}
        aria-labelledby="form-dialog-title"
        paymentMethodId={selectedPaymentMethods && selectedPaymentMethods.id}
      ></PaymentMethodModal>
      <MainHeader>
        <Title>{i18n.t("paymentMethod.title")}</Title>
        <MainHeaderButtonsWrapper>
          <Hidden mdDown>
            <TextField
              placeholder={i18n.t("global.searchPlaceholder")}
              type="search"
              value={searchParam}
              onChange={handleSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search style={{ color: "gray" }} />
                  </InputAdornment>
                ),
              }}
            />
          </Hidden>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenPaymentMethodModal}
          >
            <AddBox /> <Hidden mdDown>{i18n.t("global.buttons.add")}</Hidden>
          </Button>
        </MainHeaderButtonsWrapper>
      </MainHeader>
      <Hidden lgUp>
        <TextField
          placeholder={i18n.t("global.searchPlaceholder")}
          type="search"
          value={searchParam}
          onChange={handleSearch}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search style={{ color: "gray" }} />
              </InputAdornment>
            ),
          }}
        />
        <br />
      </Hidden>
      <Paper
        className={classes.mainPaper}
        variant="outlined"
        onScroll={handleScroll}
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                {i18n.t("global.name")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("global.description")}
              </TableCell>
              <TableCell align="center">
                Status
              </TableCell>
              <TableCell align="center">
                {i18n.t("global.actions")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {paymentMethod.map((paymentMethod) => (
                <TableRow key={paymentMethod.id}>
                  <TableCell align="center">{paymentMethod.name}</TableCell>
                  <TableCell align="center">{paymentMethod.description}</TableCell>
                  <TableCell align="center">
                    {paymentMethod.status
                      ? <CheckCircle style={{ color: "green" }} />
                      : <CheckCircle style={{ color: "grey" }} />
                    }
                  </TableCell>
                  <TableCell align="center">
                    <IconButton
                      size="small"
                      onClick={() => handleEditPaymentMethods(paymentMethod)}
                    >
                      <Edit />
                    </IconButton>

                    <IconButton
                      size="small"
                      onClick={(e) => {
                        setConfirmModalOpen(true);
                        setDeletingPaymentMethods(paymentMethod);
                      }}
                    >
                      <DeleteOutline />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              {loading && <TableRowSkeleton columns={4} />}
            </>
          </TableBody>
        </Table>
      </Paper>
    </MainContainer>
  );
};

export default PaymentMethods;
