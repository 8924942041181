import React, { useState, useContext, useEffect, useRef } from "react";
import clsx from "clsx";

import {
  makeStyles,
  Drawer,
  Toolbar,
  List,
  Typography,
  Divider,
  MenuItem,
  IconButton,
  Menu,
  Box,
  Popover,
  Switch,
  Button
} from "@material-ui/core";


import MenuIcon from "@material-ui/icons/Menu";
import { ChevronLeft, Brightness4, BrightnessHigh, AccountCircle } from "@material-ui/icons";

import MainListItems from "./MainListItems";
// import NotificationsPopOver from "../components/NotificationsPopOver";
import UserModal from "../components/UserModal";
import ExpirationNotice from "../components/ExpirationNotice";
import { AuthContext } from "../context/Auth/AuthContext";
import BackdropLoading from "../components/BackdropLoading";
import { i18n } from "../translate/i18n";
import logoBlackMini from "../assets/logo-send4whats-black-mini.png";
import logoWhiteMini from "../assets/logo-send4whats-white-mini.png";
import { SettingsContext } from "../context/Settings/SettingsContext";
import ColorModeContext from "../layout/themeContext";
import ColorPicker from "../components/ColorPicker";
import HexToRgb from "../components/HexToRgb";

const drawerWidth = 100

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    background: `linear-gradient(to right, 
      ${theme.palette.primary.main},  
      rgba(
        ${HexToRgb(theme.palette.primary.main, true).r}, 
        ${HexToRgb(theme.palette.primary.main, true).g},
        ${HexToRgb(theme.palette.primary.main, true).b}, 
      0.9)
       )`,
  },
  toolbar: {
    flex: 1,
    display: "flex",
    justifyContent: "space-between",
    paddingLeft: 5,
    paddingRight: 2,
    maxHeight: 47,
    color: "#fff"
  },
  toolbarLogo: {
    marginTop: 5,
    marginLeft: 17,
    alignItems: "center",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      marginLeft: 25,
    },
  },
  appBar: {
    margin: 0,
    left: 0,
    right: 0,
    paddingLeft: 10,
    width: "unset",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 5,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
    color: theme.palette.primary.contrastText,
    marginLeft: 5
  },
  drawerPaper: {
    border: "none",
    position: "relative",
    whiteSpace: "nowrap",
    height: "100%",
    width: drawerWidth,
    [theme.breakpoints.down("md")]: {
      width: drawerWidth +15,
    },
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: theme.palette.primary.main,
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: {
    margin: "48px 5px 5px 1px",
    display: "flex",
    height: "calc(100vh - 53px)",
    flexDirection: "column",
    overflow: "hidden",
    borderRadius: 10,
    padding: 5,
    border: "1px solid " + theme.palette.primary.main,
    background: `linear-gradient(to right, 
      rgba(
      ${HexToRgb(theme.palette.primary.contrastText, true).r}, 
      ${HexToRgb(theme.palette.primary.contrastText, true).g},
      ${HexToRgb(theme.palette.primary.contrastText, true).b}, 
    0.5),
    rgba(
      ${HexToRgb(theme.palette.primary.contrastText, true).r}, 
      ${HexToRgb(theme.palette.primary.contrastText, true).g},
      ${HexToRgb(theme.palette.primary.contrastText, true).b}, 
    0.5)
     )`,
  },
  content: {
    flex: 1,
    overflow: "auto",
  },
  userName: {
    fontSize: "75%",
    paddingLeft: "5px",
    color: theme.palette.primary.contrastText,
  },
  colorText: {
    color: theme.palette.primary.contrastText,
  },

  popoverPaper: {
    width: "100%",
    maxWidth: 350,
    marginTop: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      maxWidth: 270,
    },
    marginBottom: 5,
    padding: 5,
    background: theme.palette.primary.main,


  },
  buttonDarkmode: {
    color: theme.palette.primary.contrastText,
    border: "1px solid " + theme.palette.primary.contrastText,
  }
}));

const LoggedInLayout = ({ children }) => {
  const classes = useStyles();
  const { colorMode } = useContext(ColorModeContext);
  const [userModalOpen, setUserModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const { handleLogout, loading } = useContext(AuthContext);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerVariant, setDrawerVariant] = useState("permanent");
  const { user } = useContext(AuthContext);
  const [clientName, setClientName] = useState(null);

  const [days, setDays] = useState(0);
  const [due, setDue] = useState(0);
  const [openDueAlert, setOpenDueAlert] = useState(false);
  const [billingUrl, setBillingUrl] = useState("");
  const [billingPrice, setBillingPrice] = useState("");
  const { settings } = useContext(SettingsContext);
  const [isOpen, setIsOpen] = useState(false);
  const anchorElTheme = useRef();

  const [colorPickerModalOpen, setColorPickerModalOpen] = useState(false);

  const [mode, setMode] = useState(window.localStorage.getItem("mode"));
  const [colorTheme, setColorTheme] = useState(window.localStorage.getItem("colorTheme"));

  useEffect(() => {
    const loadsettings = async () => {
      const dueDate = await settings.find(t => t.key === "dueDate");

      const billingUrl = await settings.find(t => t.key === "billingUrl");
      setBillingUrl(billingUrl?.value);
      const billingPrice = await settings.find(t => t.key === "billingPrice");
      setBillingPrice(billingPrice?.value);

      const second = 1000;
      const minute = second * 60;
      const hour = minute * 60;
      const day = hour * 24;

      let date_ini = new Date();
      let date_end = new Date(dueDate?.value);

      let diff = date_end.getTime() - date_ini.getTime();

      setDue(date_end.toLocaleDateString('pt-br'));
      setDays(Math.floor(diff / day) + 1);
      if ((Math.floor(diff / day) + 1) < 6) setOpenDueAlert(true);

      setOpenDueAlert(true);

    }
    loadsettings();
  }, [settings]);

  useEffect(() => {
    if (document.body.offsetWidth > 600) {
      setDrawerOpen(true);
    }

    let getClientName = window.location.hostname;
    getClientName = getClientName.split(".");
    getClientName = getClientName[0];
    setClientName(getClientName);

  }, []);

  useEffect(() => {
    if (document.body.offsetWidth < 600) {
      setDrawerVariant("temporary");
    } else {
      setDrawerVariant("permanent");
    }
  }, [drawerOpen]);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setMenuOpen(false);
  };

  const handleOpenUserModal = () => {
    setUserModalOpen(true);
    handleCloseMenu();
  };

  const handleClickLogout = () => {
    handleCloseMenu();
    handleLogout();
  };

  const drawerClose = () => {
    if (document.body.offsetWidth < 600) {
      setDrawerOpen(false);
    }
  };
  const drawerCloseProps = () => {
    if (document.body.offsetWidth < 600) {
      setDrawerOpen(!drawerOpen);
    }
  };

  function handleOpenDueAlert() {
    if (days >= 0) {
      setOpenDueAlert(false);
    }
  }

  if (loading) {
    return <BackdropLoading />;
  }



  const handleTheme = () => {
    setIsOpen(prevState => !prevState);
  };

  const handleThemeAway = () => {
    setIsOpen(false);
  };
  const handleThemeChoose = () => {
    const theme = (mode === "dark" ? "light" : "dark");
    window.localStorage.setItem("mode", theme);
    setMode(theme)
    colorMode.toggleColorMode(theme);
    setIsOpen(false);
  };
  const handleColorChoose = (color) => {
    colorMode.setColorTheme(color);
    setColorTheme(color)
    window.localStorage.setItem("colorTheme", color)
  };

  return (
    <div className={classes.root}>

      <Drawer
        variant={drawerVariant}
        className={drawerOpen ? classes.drawerPaper : classes.drawerPaperClose}
        classes={{
          paper: clsx(
            classes.drawerPaper,
            !drawerOpen && classes.drawerPaperClose
          ),
        }}
        open={drawerOpen}
        style={{ boxShadow: `${drawerVariant === "temporary" ? "0 0 6px 0 gray" : ""}` }}
      >
        {drawerVariant === "temporary" &&
          <IconButton style={{ position: "absolute" }}>
            <ChevronLeft onClick={() => setDrawerOpen(!drawerOpen)} />
          </IconButton>
        }
        <div className={classes.toolbarLogo} onClick={() => drawerCloseProps()}>
          {HexToRgb(colorTheme) === "#000000" ? (
            <img alt="send4whats.com" src={logoBlackMini} height={"72"} />
          ) : (
            <img alt="send4whats.com" src={logoWhiteMini} height={"72"} />
            
          )}
        </div>
        <List>
          <MainListItems drawerClose={drawerClose} drawerOpen={drawerOpen} />
        </List>
        <Divider />
      </Drawer>

      <main className={classes.content}>
        <UserModal
          open={userModalOpen}
          onClose={() => setUserModalOpen(false)}
          userId={user?.id}
        />
        <Box
          position="absolute"
          className={clsx(classes.appBar, drawerOpen && classes.appBarShift)}
          color={process.env.NODE_ENV === "development" ? "inherit" : "primary"}
        >
          <Toolbar variant="dense" className={classes.toolbar}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={() => setDrawerOpen(!drawerOpen)}
              className={clsx(
                classes.menuButton,
                drawerOpen && classes.menuButtonHidden
              )}
            >
              <MenuIcon />
            </IconButton>

            <Typography
              component="h5"
              variant="h6"
              color="inherit"
              noWrap
              className={classes.title}
            >
              {clientName}
            </Typography>

            {/* {user.id && <NotificationsPopOver />} */}

            <div>
              {/* CHOOSE THEME */}
              <IconButton
                onClick={handleTheme}
                ref={anchorElTheme}
                aria-label="Open Notifications"
                color="inherit"
                className={classes.colorText}
              >
                {mode === "dark" ? <Brightness4 /> : <BrightnessHigh />}
              </IconButton>
              <Popover

                disableScrollLock
                open={isOpen}
                anchorEl={anchorElTheme.current}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                classes={{ paper: classes.popoverPaper }}
                onClose={handleThemeAway}
              >
                <Box
                  top={0}
                  left={0}
                  bottom={1}
                  right={0}
                  paddingTop={1}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => handleThemeChoose()}
                    className={classes.buttonDarkmode}
                  >
                    <Switch checked={mode === "dark" ? true : false} /> <Typography variant="body1" component="div" > {mode === "dark" ? " Desativar modo Dark" : " Ativar modo Dark"}</Typography>
                  </Button>
                </Box>
                <ColorPicker
                  open={colorPickerModalOpen}
                  handleClose={() => setColorPickerModalOpen(false)}
                  onChange={(color) => { handleColorChoose(color) }}
                  noDialog={true}
                  currentColor={colorTheme}
                />
              </Popover>

              {/* CHOOSE THEME */}

              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
                className={classes.colorText}
              >
                <AccountCircle />
                <Typography
                  component="h5"
                  variant="h6"
                  color="inherit"
                  noWrap
                  className={classes.title}
                >
                  {user.name}
                </Typography>
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={menuOpen}
                onClose={handleCloseMenu}
              >
                <MenuItem onClick={handleOpenUserModal}>
                  {i18n.t("mainDrawer.appBar.user.profile")}
                </MenuItem>
                <MenuItem onClick={handleClickLogout}>
                  {i18n.t("mainDrawer.appBar.user.logout")}
                </MenuItem>
              </Menu>
            </div>
          </Toolbar>
        </Box>
        <div className={classes.appBarSpacer}>
          {days < 6 &&
            <ExpirationNotice days={days} due={due} billingUrl={billingUrl} billingPrice={billingPrice} openDueAlert={openDueAlert} handleAlert={handleOpenDueAlert} />
          }
          {
            days >= 0 || user.profile === "super"
              ? children ? children : null
              : null
          }
        </div>
      </main>
    </div>
  );
};

export default LoggedInLayout;
