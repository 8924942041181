import React, { useEffect, useState } from "react";
import ClipboardJS from "clipboard";

import { makeStyles } from "@material-ui/core/styles";

import {
	Avatar,
	IconButton,
	Paper,
	Typography,
	Link,
	Tooltip,
	Card,
	CardHeader,
	Divider
} from "@material-ui/core";

import {
	Person,
	Edit,
	WhatsApp,
	FileCopy,
	DeleteOutline,
	AddCircle
} from "@material-ui/icons";

import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";
import { toast } from "react-toastify";

import api from "../../services/api";
import TableRowSkeleton from "../TableRowSkeleton";

import ContactLinkModal from "../ContactLinkModal";
import ConfirmationModal from "../ConfirmationModal";

const useStyles = makeStyles(theme => ({
	contactDetails: {
		margin: 8,
		// padding: 8,
		display: "flex",
		flexDirection: "column",
		// borderRadius: 10,
		// borderColor: theme.palette.primary.main,
		border: "none"
	},
	copy: {
		marginLeft: 2,
		cursor: "pointer",
	},
	actions: {
		marginTop: -10,
		marginLeft: -5
	},
	text: {
		color: theme.palette.textPrimary.main
	},
	description: {
		display: "flex",
		justifyContent: "left",
		padding: 10,
	},
	infoTag: {
		flex: 1,
		display: "flex",
		paddingTop: 3,
		paddingLeft: 5,
		marginRight: 1,
		borderRadius: 3,

	},
	infoTagMany: {
		borderBottom: "1px solid #e9e9e9",
		display: "flex",
		flexFlow: "row nowrap",
		justifySelf: "center",
		alignSelf: "center",
	},
	infoTagBlue: {
		color: "RoyalBlue",
	},
	infoTagGreen: {
		color: "#00a859",
	},
	infoButton: {
		paddingRight: 5,
		cursor: "pointer",
	},
	avatar: {
		verticalAalign: 'middle',
		width: 50,
		height: 50,
		borderRadius: 50,
		objectFit: "cover",
	}
}));


const ContactDrawerLink = ({ contact }) => {
	const classes = useStyles();
	const [loading, setLoading] = useState(false);


	const [deletingContactLinks, setDeletingContactLinks] = useState(null);
	const [confirmModalOpen, setConfirmModalOpen] = useState(false);
	const [selectedContactLinks, setSelectedContactLinks] = useState(null);
	const [contactLinkModalOpen, setContactLinkModalOpen] = useState(false);
	const [contactLinks, setContactLinks] = useState([]);
	const [selectedContact, setSelectedContact] = useState(null);

	const handleOpenContactLinkModal = () => {
		setSelectedContact(contact)
		setSelectedContactLinks(null);
		setContactLinkModalOpen(true);
	};

	const handleCloseContactLinkModal = () => {
		setSelectedContactLinks(null);
		setContactLinkModalOpen(false);
		fetchContactsLinks();
	};

	const handleEditContactLinks = (contactLink) => {
		setSelectedContact(contact)
		setSelectedContactLinks(contactLink);
		setContactLinkModalOpen(true);
	};

	new ClipboardJS('#copy');

	const fetchContactsLinks = async () => {
		try {
			setLoading(true);
			const { data } = await api.get(`/contactLinks/list/${contact?.id}`);
			setContactLinks(data);
			setLoading(false);
		} catch (err) {
			toastError(err);
		}
	};

	useEffect(() => {

		const delayDebounceFn = setTimeout(() => {

			// eslint-disable-next-line
			{ contact.id > 0 && fetchContactsLinks(); }
		}, 500);
		return () => clearTimeout(delayDebounceFn);
		// eslint-disable-next-line
	}, [contact]);

	const handleDeleteContactLinks = async (id) => {
		setLoading("Excluindo...");
		try {
			await api.delete(`/contactLinks/${id}`);
			toast.success(i18n.t("global.toasts.deleted"));
			await fetchContactsLinks();
			setLoading(false);

		} catch (err) {
			setLoading(false);
			toastError(err);
		}
		setDeletingContactLinks(null);
	};


	return (
		loading ? (
			<TableRowSkeleton columns={4} />
		) : (

			<Paper square variant="outlined" className={classes.contactDetails}>
				<ConfirmationModal
					title={
						deletingContactLinks &&
						`${i18n.t("global.deleteTitle")} ${i18n.t("contactLink.name")}: ${deletingContactLinks.name}?`
					}
					open={confirmModalOpen}
					onClose={setConfirmModalOpen}
					onConfirm={() => handleDeleteContactLinks(deletingContactLinks.id)}
				>
					{i18n.t("global.deleteMessage")}
				</ConfirmationModal>

				<ContactLinkModal
					open={contactLinkModalOpen}
					onClose={handleCloseContactLinkModal}
					aria-labelledby="form-dialog-title"
					contact={selectedContact}
					contactLink={selectedContactLinks}
				></ContactLinkModal>

				<Typography variant="button" display="block" gutterBottom>
					<IconButton
						size="small"
						onClick={() => handleOpenContactLinkModal()}
						style={{ top: -45, left: 240 }}
					>
						<Tooltip title={i18n.t("global.buttons.new")} >
							<AddCircle size="small" />
						</Tooltip>
					</IconButton>
				</Typography>
				<div style={{ marginTop: -30 }} >
					{contactLinks?.map(link => (
						<>
							<Card key={link?.id} style={{ border: "1px solid grey", marginBottom: 5 }}>
								<CardHeader
									avatar={
										<>
											<Avatar
												alt={link?.contactId === contact?.id ? link?.contactLinkIdName : link?.contactIdName}
												src={link?.contactId === contact?.id ? link?.contactLinkIdprofilePicUrl : link?.contactIdprofilePicUrl}
												className={classes.avatar}
											></Avatar>
											<div className={classes.actions}>
												<IconButton
													size="small"
													onClick={() => handleEditContactLinks(link)}
												>
													<Tooltip title={i18n.t("global.buttons.edit")} >
														<Edit />
													</Tooltip>
												</IconButton>
												<IconButton
													size="small"
													onClick={(e) => {
														setConfirmModalOpen(true);
														setDeletingContactLinks({
															name: link?.contactId === contact?.id ? link?.contactLinkIdName : link?.contactIdName,
															id: link?.id
														})
													}}
												>
													<DeleteOutline color="secondary" />
												</IconButton>
											</div>
										</>
									}
									title={
										<div className={classes.infoTag}>
											<Person className={`${classes.infoButton} ${classes.infoTagBlue}`} />
											<Typography>{link?.contactId === contact?.id ? link?.contactLinkIdName : link?.contactIdName}&nbsp; </Typography>
										</div>
									}
									subheader={
										<>
											<div className={classes.infoTag}>
												<WhatsApp className={`${classes.infoButton} ${classes.infoTagGreen}`} />
												<Link className={classes.text} id={`contactLinkNumber${link?.contactId === contact?.id ? link?.contactLinkId : link?.contactId}`} href={`tel:${link?.contactLinkIdNumber}`}>
													{link?.contactId === contact?.id ? link?.contactLinkIdNumber : link?.contactIdNumber}
												</Link>
												<FileCopy
													id="copy"
													className={classes.copy}
													data-clipboard-action="copy"
													data-clipboard-target={`#contactLinkNumber${link?.contactId === contact?.id ? link?.contactLinkId : link?.contactId}`}
												// fontSize="small"
												/>
											</div>
										</>
									}
								/>
								{link?.description &&
									<>
										<Divider />
										<div className={classes.description}>
											{link?.description}
										</div>
									</>
								}
							</Card>

						</>


					))}
				</div>
			</Paper>

		)
	);
};

export default ContactDrawerLink;
