import React, { useState, useEffect, useMemo } from "react";
import Routes from "./routes";
import "react-toastify/dist/ReactToastify.css";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { red, grey } from '@material-ui/core/colors'
import CssBaseline from '@material-ui/core/CssBaseline';
import { ptBR } from "@material-ui/core/locale";

import ColorModeContext from "./layout/themeContext";

import HexToRgb from "./components/HexToRgb";


const App = () => {
  const [locale, setLocale] = useState();
  const [mode, setMode] = useState(window.localStorage.getItem("mode") || "light");
  const [colorTheme, setColorTheme] = useState(window.localStorage.getItem("colorTheme") || "#00a857");

  useEffect(() => {

    const i18nlocale = localStorage.getItem("i18nextLng");
    const browserLocale =
      i18nlocale.substring(0, 2) + i18nlocale.substring(3, 5);

    if (browserLocale === "ptBR") {
      setLocale(ptBR);
    }
  }, []);


  const themes = {

    light: {
      base: "light",
      primary: { main: colorTheme, contrastText: HexToRgb(colorTheme) },
      textPrimary: { main: grey[900] },
      card: { main: "rgba(0, 0, 0, 0.05)"}
    },
    dark: {
      base: "dark",
      primary: { main: colorTheme, contrastText: HexToRgb(colorTheme) },
      textPrimary: { main: grey[500] },
      card: { main:  "rgba(0, 0, 0, 0.3)"}
    },

  }

  const colorMode = useMemo(
    () => ({
      toggleColorMode: (t) => {
        setMode(window.localStorage.getItem("mode"));
      },
      setColorTheme: (color) => {
        setColorTheme(color);
      },

    }),
    []
  );

  const theme = createTheme(
    {
      scrollbarStyles: {
        "&::-webkit-scrollbar": {
          width: "5px",
          height: "5px",
        },
        "&::-webkit-scrollbar-thumb": {
          boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
          backgroundColor: themes[mode].primary.main,
        },
      },
      mode,
      palette: {
        type: themes[mode].base,
        primary: {
          main: themes[mode].primary.main,
          contrastText: themes[mode].primary.contrastText,
        },
        secondary: { main: red[700] },
        textPrimary: {
          main: themes[mode].textPrimary.main,
        },
        card: { main: themes[mode].card.main },
        action: {
          hover: `rgba(
            ${HexToRgb(themes[mode].primary.main, true).r}, 
            ${HexToRgb(themes[mode].primary.main, true).g},
            ${HexToRgb(themes[mode].primary.main, true).b}, 
          0.1)`,
          selected: `rgba(
            ${HexToRgb(themes[mode].primary.main, true).r}, 
            ${HexToRgb(themes[mode].primary.main, true).g},
            ${HexToRgb(themes[mode].primary.main, true).b}, 
          0.2)`,

        },
      }
    },
    locale
  );

  useEffect(() => {
    window.localStorage.setItem("mode", mode);
    window.localStorage.setItem("colorTheme", colorTheme);
  }, [mode, colorTheme]);

  return (
    <ColorModeContext.Provider value={{ colorMode }}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Routes />
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default App;
