const FormatContactNumber = ( contactNumber ) => {
  
  const getContactNumber = contactNumber;
  try {
    var numberDDI = getContactNumber.substr(0, 2);
    var numberDDD = getContactNumber.substr(2, 2);
    var numberWZap1;
    var numberWZap2;
    if (numberDDI === "55") {

      numberWZap1 = getContactNumber.substr(-8, 4);
      numberWZap2 = getContactNumber.substr(-4, 4);
      if (numberDDD <= 30) {
        return `${numberDDI} ${numberDDD} 9${numberWZap1}-${numberWZap2}`;
      } else {
        return `${numberDDI} ${numberDDD} ${numberWZap1}-${numberWZap2}`;
      }

    } else {
      return contactNumber;
    }
  } catch (error) {
    return contactNumber;
  }

};

export default FormatContactNumber;
