import React, { useEffect, useState } from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";

import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import ButtonWithSpinner from "../ButtonWithSpinner";
import toastError from "../../errors/toastError";
import { toast } from "react-toastify";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	maxWidth: {
		width: "100%",
		marginTop: 20
	},
}));

const SendAlertNotification = ({ modalOpen, onClose, id, openTicketonSendAlert }) => {
	const classes = useStyles();
	const [openTicket, setOpenTicket] = useState(openTicketonSendAlert);
	const [loading, setLoading] = useState(false);

	const handleClose = () => {
		setLoading(false);
		onClose();
		setOpenTicket("");
	};

	const handleSendNotification = async () => {
		setLoading(true);
		try {
			await api.get(`/subscription/notificationsContactId/${id}`, {
				params: { openTicket },
			});
			toast.success(i18n.t("subscription.sendAlertModal.success"));
			setLoading(false);
			handleClose();

		} catch (err) {
			setLoading(false);
			onClose();
			setOpenTicket("");
			toastError(err);
		}
	};
	useEffect(() => {
		setOpenTicket(openTicketonSendAlert);
	}, [modalOpen, openTicketonSendAlert])

	return (
		<>
			<Dialog open={modalOpen} onClose={handleClose}>
				<DialogTitle id="form-dialog-title">
					{i18n.t("subscription.sendAlertModal.title")}
				</DialogTitle>
				<DialogContent dividers>
					<FormControl variant="outlined" margin="dense" className={classes.maxWidth} >
						<InputLabel>{i18n.t("subscription.sendAlertModal.openTicket.name")}</InputLabel>
						<Select
							label={i18n.t("subscription.sendAlertModal.openTicket.name")}
							margin="dense"
							variant="outlined"
							native
							id="openTicket"
							name="openTicket"
							onChange={(e) => setOpenTicket(e.target.value)}
							value={openTicket}
							className={classes.textField}
						>
							<option value="false">
								{i18n.t("subscription.sendAlertModal.openTicket.options.false")}
							</option>
							<option value="true">
								{i18n.t("subscription.sendAlertModal.openTicket.options.true")}
							</option>
							<option value="trueClose">
								{i18n.t("subscription.sendAlertModal.openTicket.options.trueClose")}
							</option>
						</Select>
					</FormControl>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={handleClose}
						color="secondary"
						disabled={loading}
						variant="outlined"
					>
						{i18n.t("global.buttons.cancel")}
					</Button>
					<ButtonWithSpinner
						variant="contained"
						type="button"
						disabled={(openTicket === "")}
						onClick={() => handleSendNotification()}
						color="primary"
						loading={loading}
					>
						{i18n.t("global.buttons.send")}
					</ButtonWithSpinner>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default SendAlertNotification;
