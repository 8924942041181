import React, { useState, useEffect, useReducer, useContext } from "react";
import openSocket from "../../services/socket-io";

import {
  Hidden,
  makeStyles,
  Paper,
  InputAdornment,
  TextField,
} from "@material-ui/core";

import { Search } from "@material-ui/icons";

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";
import clsx from "clsx";

import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import toastError from "../../errors/toastError";
import { SettingsContext } from "../../context/Settings/SettingsContext";
import PaymentsListTable from "../../components/PaymentsListTable";
import ContactDrawer from "../../components/ContactDrawer";


const reducer = (state, action) => {
  if (action.type === "LOAD_PAYMENTES") {
    const payments = action.payload;
    const newPayments = [];

    payments.forEach((payment) => {
      const paymentIndex = state.findIndex((q) => q.id === payment.id);
      if (paymentIndex !== -1) {
        state[paymentIndex] = payment;
      } else {
        newPayments.push(payment);
      }
    });

    return [...state, ...newPayments];
  }

  if (action.type === "UPDATE_PAYMENTES") {
    const payment = action.payload;
    const paymentIndex = state.findIndex((q) => q.id === payment.id);

    if (paymentIndex !== -1) {
      state[paymentIndex] = payment;
      return [...state];
    } else {
      return [payment, ...state];
    }
  }

  if (action.type === "DELETE_PAYMENTES") {
    const paymentId = action.payload;

    const paymentIndex = state.findIndex((q) => q.id === paymentId);
    if (paymentIndex !== -1) {
      state.splice(paymentIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const drawerWidth = 320;

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  root: {
    display: "flex",
    height: "100%",
    position: "relative",
    overflow: "hidden",
    marginLeft: "10px",
    borderRadius: "10px",
  },

  mainWrapper: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    // marginRight: -drawerWidth,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

  mainWrapperShift: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    marginRight: drawerWidth,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    // marginRight: 0,
  },
}));

const Payments = () => {
  const classes = useStyles();
  const { settings } = useContext(SettingsContext);
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchParam, setSearchParam] = useState("");
  const [payments, dispatch] = useReducer(reducer, []);
  const [hasMore, setHasMore] = useState(false);
  const [modulesStreaming, setModulesStreaming] = useState("disabled");
  const [contact, setContact] = useState({});
  const [drawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    const loadsettings = async () => {
      const modulesStreaming = await settings.find(t => t.key === "modulesStreaming");
      setModulesStreaming(modulesStreaming?.value);
    }
    loadsettings();
  }, [settings]);

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchPayments = async () => {
        try {
          const { data } = await api.get("/subscriptionPayment/", {
            params: { searchParam, pageNumber },
          });
          dispatch({ type: "LOAD_PAYMENTES", payload: data.payments });
          setHasMore(data.hasMore);
          setLoading(false);
        } catch (err) {
          toastError(err);
        }
      };
      fetchPayments();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber]);

  useEffect(() => {
    const socket = openSocket();

    socket.on("payment", (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_PAYMENTES", payload: data.payment });
      }

      if (data.action === "delete") {
        dispatch({
          type: "DELETE_PAYMENTES",
          payload: +data.paymentId,
        });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };

  const handleDrawerOpen = (contacts, paymentsId) => {
    setContact(contacts)
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
    setContact(0)
  };

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  return (
    <div className={classes.root} id="drawer-container">
      <MainContainer>
        <ContactDrawer
          open={drawerOpen}
          handleDrawerClose={handleDrawerClose}
          contact={contact}
          loading={loading}
          openTicketonSendAlert="false"
        />
        <MainHeader>
          <Title>{i18n.t("payment.title")}</Title>
          <MainHeaderButtonsWrapper >

            <Hidden mdDown>
              <TextField
                placeholder={i18n.t("global.searchPlaceholder")}
                type="search"
                value={searchParam}
                onChange={handleSearch}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search style={{ color: "gray" }} />
                    </InputAdornment>
                  ),
                }}
              />
            </Hidden>
          </MainHeaderButtonsWrapper>
        </MainHeader>
        <Hidden lgUp>
          <TextField
            placeholder={i18n.t("global.searchPlaceholder")}
            type="search"
            value={searchParam}
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search style={{ color: "gray" }} />
                </InputAdornment>
              ),
            }}
          />
          <br />
        </Hidden>
        <Paper
          variant="outlined"
          onScroll={handleScroll}
          style={{ overflow: 'auto', paddingRight: 20 }}
          className={clsx(`${classes.mainWrapper} ${classes.mainPaper}`, {
            [classes.mainWrapperShift]: drawerOpen,
          })}
        >

          <PaymentsListTable
            payments={payments}
            handleDrawerOpen={handleDrawerOpen}
            drawerOpen={drawerOpen}
            modules={{ modulesStreaming }}
            contactId={contact.id}
          />

          {loading && <TableRowSkeleton columns={4} />}

        </Paper>
      </MainContainer >
    </div>
  );
};

export default Payments;
