import React, { useState, useEffect, useReducer, useCallback, useContext } from "react";

import { toast } from "react-toastify";
import openSocket from "../../services/socket-io";
import { useHistory } from "react-router-dom";

import { Paper, IconButton, Button, Badge, Box, Grid, Card, CardHeader, Avatar } from "@material-ui/core";

import { WhatsApp, FormatListBulleted, DeleteOutline, Edit } from '@material-ui/icons';

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";

import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import TagModal from "../../components/TagModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import toastError from "../../errors/toastError";
import HexToRgb from "../../components/HexToRgb";

import { SettingsContext } from "../../context/Settings/SettingsContext";

const reducer = (state, action) => {
  if (action.type === "LOAD_TAGS") {
    const tags = action.payload;
    const newTags = [];

    tags.forEach((tag) => {
      const tagIndex = state.findIndex((s) => s.id === tag.id);
      if (tagIndex !== -1) {
        state[tagIndex] = tag;
      } else {
        newTags.push(tag);
      }
    });

    return [...state, ...newTags];
  }

  if (action.type === "UPDATE_TAGS") {
    const tag = action.payload;
    const tagIndex = state.findIndex((s) => s.id === tag.id);

    if (tagIndex !== -1) {
      state[tagIndex] = tag;
      return [...state];
    } else {
      return [tag, ...state];
    }
  }

  if (action.type === "DELETE_TAG") {
    const tagId = action.payload;

    const tagIndex = state.findIndex((s) => s.id === tagId);
    if (tagIndex !== -1) {
      state.splice(tagIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const Tags = () => {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [selectedTag, setSelectedTag] = useState(null);
  const [deletingTag, setDeletingTag] = useState(null);
  const [deletingContact, setDeletingContact] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [confirmModalDeleteContactOpen, setConfirmModalDeleteContactOpen] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [tags, dispatch] = useReducer(reducer, []);
  const [tagModalOpen, setTagModalOpen] = useState(false);
  const { settings } = useContext(SettingsContext);
  const [modulesOnlyCampaigns, setModulesOnlyCampaigns] = useState();


  useEffect(() => {
    const loadsettings = async () => {

      const modulesOnlyCampaigns = await settings.find(t => t.key === "modulesOnlyCampaigns");
      setModulesOnlyCampaigns(modulesOnlyCampaigns?.value);

    }
    loadsettings();
  }, [settings]);

  const fetchTags = useCallback(async () => {
    try {
      const { data } = await api.get("/tags/listCards");
      dispatch({ type: "LOAD_TAGS", payload: data });
      setLoading(false);
    } catch (err) {
      toastError(err);
    }
  }, []);

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      fetchTags();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber, fetchTags]);

  useEffect(() => {
    const socket = openSocket();

    socket.on("user", (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_TAGS", payload: data.tags });
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_USER", payload: +data.tagId });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const handleOpenTagModal = () => {
    setSelectedTag(null);
    setTagModalOpen(true);
  };

  const handleCloseTagModal = () => {
    setSelectedTag(null);
    setTagModalOpen(false);
  };

  const handleEditTag = (tag) => {
    setSelectedTag(tag);
    setTagModalOpen(true);
  };

  const handleDeleteTag = async (tagId) => {
    try {
      await api.delete(`/tags/${tagId}`);
      toast.success(i18n.t("tags.toasts.deleted"));
    } catch (err) {
      toastError(err);
    }
    setDeletingTag(null);
    setSearchParam("");
    setPageNumber(1);

    dispatch({ type: "RESET" });
    setPageNumber(1);
    await fetchTags();
  };

  const handleOpenTags = () => {
    history.push("/tags");
  };


  const handleSaveTicket = async (contactId) => {
    if (!contactId) return;
    setLoading(true);
    try {
      const { data: ticket } = await api.post("/tickets", {
        contactId: contactId,
        status: "open",
      });
      history.push(`/tickets/${ticket.id}`);
    } catch (err) {
      toastError(err);
    }
    setLoading(false);
  };

  const handleDeleteContact = async (contactId, tagID) => {
    try {
      await api.delete(`/tags/deleteContact/${contactId}-${tagID}`);
      document.getElementById(`${contactId}${tagID}`).remove();
      setDeletingContact(null);
      toast.success(i18n.t("tags.toasts.contactDeleted"));
    } catch (err) {
      toastError(err);
    }

  };

  return (
    <MainContainer>
      <ConfirmationModal
        title={
          deletingTag &&
          `${i18n.t("tags.confirmModalDeleteContact.deleteTitle")}`
        }
        open={confirmModalOpen}
        onClose={setConfirmModalOpen}
        onConfirm={() => handleDeleteTag(deletingTag.id)}
      >
        {i18n.t("tags.confirmModalDeleteContact.deleteMessage")}
      </ConfirmationModal>
      <ConfirmationModal
        title={
          deletingContact &&
          `${i18n.t("tags.confirmModalDeleteContact.deleteTitle")}`
        }
        open={confirmModalDeleteContactOpen}
        onClose={setConfirmModalDeleteContactOpen}
        onConfirm={() => handleDeleteContact(deletingContact.contactId, deletingContact.tagId)}
      >
        {i18n.t("tags.confirmModalDeleteContact.deleteMessage")}
      </ConfirmationModal>
      <TagModal
        open={tagModalOpen}
        onClose={handleCloseTagModal}
        reload={fetchTags}
        aria-labelledby="form-dialog-title"
        tagId={selectedTag && selectedTag.id}
      />
      <MainHeader>
        <Title>{i18n.t("tags.title")}</Title>
        <MainHeaderButtonsWrapper>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenTags}
          >
            <FormatListBulleted />
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenTagModal}
          >
            {i18n.t("tags.buttons.add")}
          </Button>
        </MainHeaderButtonsWrapper>
      </MainHeader>
      <Paper style={{ overflow: 'auto', paddingRight: 20 }}>
        <Box sx={{ flexGrow: 1 }}>

          <Grid container spacing={3} style={{ paddingTop: 10 }} >
            {tags.map((tag, i) => (
              <Grid key={tag.id} id={tag.id} sx={{ border: 1 }} item xs={3}  >
                <Box sx={{ flexGrow: 1, boxShadow: "0 0 .4ren #666", borderColor: tag.color || '#808080', border: 1, bgcolor: "#F2F2F2", margin: 3, padding: 3 }}>
                  <div style={{ backgroundColor: tag.color || '#808080', textShadow: '1px 1px 1px ' + HexToRgb(tag.color || '#808080'), color: HexToRgb(tag.color || '#808080'), textAlign: 'center', padding: 5, borderRadius: 5 }}>
                    <Badge overlap="rectangular" badgeContent={tag.contacts.length} color="primary">
                      {tag.name}
                    </Badge>
                    <IconButton
                      size="small"
                      onClick={() => handleEditTag(tag)}
                    >
                      <Edit style={{ color: HexToRgb(tag.color || '#808080') }} />
                    </IconButton>
                    <IconButton
                      style={{ color: HexToRgb(tag.color || '#808080') }}
                      size="small"
                      onClick={(e) => {
                        setConfirmModalOpen(true);
                        setDeletingTag(tag);
                      }}
                    >
                      <DeleteOutline />
                    </IconButton>
                  </div>
                  <div style={{ overflow: 'auto', maxHeight: 280, minHeight: 280, margin: 3, textAlign: 'center', padding: 2, }}>
                    {tag.contacts.map((contacts) => (
                      <Card key={`${contacts.id}${tag.id}`} id={`${contacts.id}${tag.id}`} sx={{ maxWidth: 280 }}
                        style={{
                          margin: 2,
                          border: "1px solid rgba(0, 0, 0, 0.12)",
                          borderBottom: `2px solid ${tag.color || '#808080'}`,
                          position: "relative",
                          borderRadius: 5,
                          with: "100%",
                          paddingTop: 0,
                          paddingBottom: 0,
                        }}
                      >
                        <CardHeader
                          avatar={
                            <Avatar src={contacts?.profilePicUrl} />
                          }
                          action={
                            <div style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              cursor: "pointer",
                            }}>
                              <DeleteOutline
                                onClick={(e) => {
                                  setConfirmModalDeleteContactOpen(true);
                                  setDeletingContact({
                                    contactId: contacts.id,
                                    tagId: tag.id
                                  }
                                  );

                                }}
                                color="secondary" />
                              {modulesOnlyCampaigns !== "enabled" &&
                                <WhatsApp onClick={() => handleSaveTicket(contacts.id)} color="primary" />
                              }
                            </div>

                          }
                          title={contacts?.name}
                          subheader={contacts?.number}
                        />
                      </Card>
                    ))}
                  </div>
                </Box>
              </Grid>
            ))}
          </Grid>

          {loading &&

            <Table size="small">
              <TableBody>
                <TableRowSkeleton columns={4} />
              </TableBody>
            </Table>
          }
        </Box>
      </Paper>
    </MainContainer>
  );
};

export default Tags;
