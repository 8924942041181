import { useState, useEffect, useContext } from "react";
import toastError from "../../errors/toastError";

import api from "../../services/api";
import { SettingsContext } from "../../context/Settings/SettingsContext";

const useTickets = ({
    isSearch,
    searchParam,
    users,
    pageNumber,
    status,
    date,
    showAll,
    queueIds,
    withUnreadMessages,
}) => {
    const [loading, setLoading] = useState(true);
    const [hasMore, setHasMore] = useState(false);
    const [tickets, setTickets] = useState([]);
    const [count, setCount] = useState(0);
	const { settings } = useContext(SettingsContext);
	const [hoursCloseTicketAuto, setHoursCloseTicketAuto] = useState("disabled");

	useEffect(() => {
	  const loadsettings = async () => {
		const hoursCloseTicketAuto = await settings.find(t => t.key === "hoursCloseTicketAuto");
		setHoursCloseTicketAuto(hoursCloseTicketAuto?.value);
	  }
	  loadsettings();
	}, [settings]);


    useEffect(() => {
        setLoading(true);
        const delayDebounceFn = setTimeout(() => {
            const fetchTickets = async() => {
                try {
                    const { data } = await api.get("/tickets", {
                        params: {
                            searchParam,
                            users,
                            pageNumber,
                            status,
                            date,
                            showAll,
                            queueIds,
                            withUnreadMessages,
                        },
                    })
                    setTickets(data.tickets)                 

                    if (status === "open" && hoursCloseTicketAuto && hoursCloseTicketAuto !== "" &&
                        hoursCloseTicketAuto !== "0" && Number(hoursCloseTicketAuto) > 0) {

                        let dataLimite = new Date()
                        dataLimite.setHours(dataLimite.getHours() - Number(hoursCloseTicketAuto))

                        data.tickets.forEach(ticket => {
                            if (ticket.status !== "closed") {
                                let dataUltimaInteracaoChamado = new Date(ticket.updatedAt)
                                if (dataUltimaInteracaoChamado < dataLimite)
                                    closeTicket(ticket)
                            }
                        })
                    }

                    setHasMore(data.hasMore)
                    setCount(data.count)
                    setLoading(false)
                } catch (err) {
                    setLoading(false)
                    toastError(err)
                }
            }

            const closeTicket = async(ticket) => {
                await api.put(`/tickets/${ticket.id}`, {
                    status: "closed",
                    userId: ticket.userId || null,
                })
            }
            
            if (isSearch && searchParam === "") {
                setTickets([]);
                setCount(0)
                setLoading(false)
                return;
            }
            fetchTickets()
        }, 500)
        return () => clearTimeout(delayDebounceFn)
    }, [
        isSearch,
        searchParam,
        users,
        pageNumber,
        status,
        date,
        showAll,
        queueIds,
        withUnreadMessages,
        hoursCloseTicketAuto
    ])

    return { tickets, loading, hasMore, count };
};

export default useTickets;