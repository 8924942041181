import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import {
  Grid,
  Paper,
  makeStyles
} from "@material-ui/core";
import TicketsManager from "../../components/TicketsManager/";
import Ticket from "../../components/Ticket/";

import { i18n } from "../../translate/i18n";
import Hidden from "@material-ui/core/Hidden";
import { SettingsContext } from "../../context/Settings/SettingsContext";

const useStyles = makeStyles((theme) => ({
  chatContainer: {
    flex: 1,
    // padding: 10,
    // paddingTop: 15,
    height: `calc(100% - 48px)`,
    //height: `100%`,
    overflowY: "hidden",
    border: "none",
  },

  chatPapper: {
    border: "none",
    // border: "1px solid red",
    // backgroundColor: "red",
    display: "flex",
    height: "100%",
  },

  contactsWrapper: {
    minWidth: "380px",
    display: "flex",
    height: "100%",
    flexDirection: "column",
    overflowY: "hidden",
  },
  contactsWrapperSmall: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    overflowY: "hidden",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  messagessWrapper: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
  },
  welcomeMsg: {
    marginLeft: 5,
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    height: "100%",
    textAlign: "center",
    borderRadius: 10,
  },
  ticketsManager: {},
  ticketsManagerClosed: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

const Chat = () => {
  const classes = useStyles();
  const { ticketId } = useParams();
  const { settings } = useContext(SettingsContext);
  const [modulesOnlyCampaigns, setModulesOnlyCampaigns] = useState();

  useEffect(() => {
    const loadsettings = async () => {

      const modulesOnlyCampaigns = await settings.find(t => t.key === "modulesOnlyCampaigns");
      setModulesOnlyCampaigns(modulesOnlyCampaigns?.value);

    }
    loadsettings();
  }, [settings]);
  

  return (
    
    <div className={classes.chatContainer}>
      <div className={classes.chatPapper}>
      {modulesOnlyCampaigns !== "enabled" &&
        <Grid container spacing={0}>
          
          {/* <Grid item xs={4} className={classes.contactsWrapper}> */}
          <Grid
            item
            xs={12}
            md={4}
            className={
              ticketId ? classes.contactsWrapperSmall : classes.contactsWrapper
            }
          >
            <TicketsManager />
          </Grid>
          <Grid item xs={12} md={8} className={classes.messagessWrapper}>
            {/* <Grid item xs={8} className={classes.messagessWrapper}> */}
            {ticketId ? (
              <>
                <Ticket />
              </>
            ) : (
              <Hidden only={["sm", "xs"]}>
                <Paper className={classes.welcomeMsg}>
                  {/* <Paper square variant="outlined" className={classes.welcomeMsg}> */}
                  <span>{i18n.t("chat.noTicketMessage")}</span>
                </Paper>
              </Hidden>
            )}
          </Grid>
          
        </Grid>
}
      </div>
    </div>
  );
};

export default Chat;
