
import React, { useContext } from "react"

import { useState, useEffect } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles"
import PropTypes from 'prop-types';
import { AuthContext } from "../../context/Auth/AuthContext";
import toastError from "../../errors/toastError";

import {
  Select,
  MenuItem,
  Paper,
  Container,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';

import {
  MonetizationOn,
} from "@material-ui/icons";

import { i18n } from "../../translate/i18n";

import { green, grey, red } from "@material-ui/core/colors";

import api from "../../services/api";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
} from 'chart.js';

import { Line } from 'react-chartjs-2';
import PriceBRL from "../../components/PriceBRL";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import BackdropLoading from "../../components/BackdropLoading";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend, ArcElement
);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles(theme => ({

  mainPaper: {
    flex: 1,
    padding: theme.spacing(2),
    margin: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  customCard: {
    textAlign: "center",
    alignContent: "center",
    overflow: "hidden",
    padding: 5,
    paddingTop: 0,
    margin: 0,
    height: 90,
    borderBottom: "2px solid " + green[500]
  },
  customCardRed: {
    borderBottom: "2px solid " + red[500]
  },
  customCardsTitle: {
    textAlign: "center",
    alignContent: "center",
    marginBottom: 15,
    fontSize: "1.1rem",
    fontWeight: 700,
  },
  customCardPrice: {
    textAlign: "center",
    alignContent: "center",
    paddingTop: 10,
    fontSize: "1.1rem",
    fontWeight: 700,
    borderRight: "1px solid lightgray"
  },
  customCardText: {
    textAlign: "center",
    alignContent: "center",
    paddingTop: 10,
    fontSize: "1rem",
    fontWeight: 700,
  },
  customCardDescription: {
    fontSize: "0.7rem",
    color: "grey",
    borderTop: "1px solid lightgray",
    padding: 2,
    marginBottom: -5,
    marginTop: 10
  },
  monetizationOn: {
    color: green[500], position: "absolute", marginLeft: -10, marginTop: -10
  },
  monetizationOnRed: {
    color: red[500]
  },

  menu: {
    justifyContent: "center",
    alignContent: "center",
    paddingTop: theme.spacing(5),
    paddingLeft: theme.spacing(3),
    paddingBottom: theme.spacing(0),
  },

  menuButtons: {
    color: green[500],
    borderRadius: "30px",
    margin: "5px",
    fontSize: "12px"
  },

  buttonDialog: {
    color: "#FFF",
    backgroundColor: green[700],
    borderRadius: "30px",
    fontSize: "12px",
    margin: 20
  },

  menuButtonsActive: {
    color: "#FFF",
    backgroundColor: green[700],
    borderRadius: "30px",
    margin: "5px",
    fontSize: "12px"
  },

  dialog: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    flexDirection: "column",
    alignItems: "center",

  },

  dialogItems: {
    margin: 20,
    width: "80%",
    height: 60
  },

  customCardChart: {
    overflow: "hidden",
    padding: 10,
    paddingBottom: 50,
  },
  customCardChartFixed: {
    height: 300,
  },

  // tableCell: {
  //   borderBottom: "none", fontSize: "12", color: "#888E93", fontWeight: "400", padding: 0, margin: 0

  // }

}))
const Dashboard = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const { user } = useContext(AuthContext);
  const [userId, setUserId] = useState(user.profile === "user" ? user.id : null);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [dateIni, setDateIni] = useState();
  const [dateFin, setDateFin] = useState();
  const [open, setOpen] = useState(false);
  const [active, setActive] = useState(0);
  const [sumStatusTrue, setSumStatusTrue] = useState(0);
  const [sumStatusFalse, setSumStatusFalse] = useState(0);
  const [sumSubscriptionsBefore, setSumSubscriptionsBefore] = useState(0);
  const [sumSubscriptionsAfter, setSumSubscriptionsAfter] = useState(0);
  const [sumPaymentMethodsQ, setSumPaymentMethodsQ] = useState(0);
  const [sumPaymentMethodsV, setSumPaymentMethodsV] = useState(0);
  const [paymentsByMoth, setPaymentsByMoth] = useState([]);
  const [paymentsByMothSum, setPaymentsByMothSum] = useState([]);
  const [subscriptionsBefore, setSubscriptionsBefore] = useState([]);
  const [subscriptionsAfter, setSubscriptionsAfter] = useState([]);
  const [subscriptionsByPlan, setSubscriptionsByPlan] = useState([]);
  const [sumTotalSubscriptionByPlan, setSumTotalSubscriptionByPlan] = useState([]);


  /*
    const labels = Utils.months({count: 12});
    const data = {
      labels: labels,
      datasets: [{
        label: 'My First Dataset',
        data: [65, 59, 80, 81, 56, 55, 40],
        backgroundColor: [
          'rgba(242, 48, 48, 0.2)',
          'rgba(255, 99, 132, 0.2)',
          'rgba(255, 159, 64, 0.2)',
          'rgba(255, 205, 86, 0.2)',
          'rgba(75, 192, 134, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(13, 113, 181, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(80, 35, 168,0.2)',
          'rgba(201, 203, 207, 0.2)',
          'rgba(127, 128, 130, 0.2)'
        ],
        borderColor: [
          'rgb(242, 48, 48)',
          'rgb(255, 99, 132)',
          'rgb(255, 159, 64)',
          'rgb(255, 205, 86)',
          'rgb(75, 192, 134)',
          'rgb(75, 192, 192)',
          'rgb(54, 162, 235)',
          'rgb(13, 113, 181)',
          'rgb(153, 102, 255)',
          'rgb(80, 35, 168)',
          'rgb(201, 203, 207)',
          'rgb(127, 128, 130)'
          
        ],
        borderWidth: 1
      }]
    };
  */


 const LineOptions = {
    maintainAspectRatio: false,

    scales: {
      x: {
        grid: {
          display: true,
        },
      },
      Y: {
        grid: {
          display: true,
        },
      },
    },
  };
  const months = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']

  const LineData = {
    labels: months,
    datasets: [
      {
        label: `${i18n.t("paymentReports.charts.paymentYearlyCount.title")} ${new Date().getFullYear()}`,
        data: paymentsByMoth,
        fill: true,
        borderColor: '#74C5F5',
        backgroundColor: "#74C5F5",
      },
      {
        label: `${i18n.t("paymentReports.charts.paymentYearlySum.title")} ${new Date().getFullYear()}`,
        data: paymentsByMothSum,
        fill: true,
        borderColor: 'lightGreen',
        backgroundColor: "lightGreen",
      },
    ]
  };


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);

  };


  const buttonSearchByUserId = ({ dateInitial, dateFinal }) => {
    handleClose();

    const delayDebounceFn = setTimeout(() => {
      setLoading(true);
      const fetchTickets = async () => {

        try {
          const { data } = await api.get("/payment/report", {
            params: {
              dateInitial,
              dateFinal
            },
          })

          setPaymentsByMoth(data?.paymentsByMoth);
          setPaymentsByMothSum(data?.paymentsByMothSum);
          setPaymentMethods(data?.paymentMethodPlan);
          setSubscriptionsBefore(data?.subscriptionsBefore);
          setSubscriptionsAfter(data?.subscriptionsAfter);
          setSubscriptionsByPlan(data?.subscriptionsByPlan);


          let sumStatusTrue = 0;
          let sumStatusFalse = 0;
          let sumSubscriptionsBeforeF = 0;
          let sumSubscriptionsAfterF = 0;
          let paymentMethodsQ = 0;
          let paymentMethodsV = 0;


          // SUBSCRIPTION BY PLAN
          let totalSubscriptionByPlan = {
            totalSubscriptionAfterQ: 0,
            totalSubscriptionAfterV: 0,
            totalSubscriptionBeforeQ: 0,
            totalSubscriptionBeforeV: 0
          }

          await data?.subscriptionsByPlan.forEach(obj => {
            totalSubscriptionByPlan["totalSubscriptionAfterQ"] += obj.totalSubscriptionAfter
            totalSubscriptionByPlan["totalSubscriptionAfterV"] += obj.totalSubscriptionAfter * obj.price
            totalSubscriptionByPlan["totalSubscriptionBeforeQ"] += obj.totalSubscriptionBefore
            totalSubscriptionByPlan["totalSubscriptionBeforeV"] += obj.totalSubscriptionBefore * obj.price
          })

          setSumTotalSubscriptionByPlan({
            totalSubscriptionAfterQ: totalSubscriptionByPlan["totalSubscriptionAfterQ"],
            totalSubscriptionAfterV: totalSubscriptionByPlan["totalSubscriptionAfterV"],
            totalSubscriptionBeforeQ: totalSubscriptionByPlan["totalSubscriptionBeforeQ"],
            totalSubscriptionBeforeV: totalSubscriptionByPlan["totalSubscriptionBeforeV"],
          })

          await data?.paymentMethodPlan.forEach(obj => {
            paymentMethodsQ += obj.totalCount
            if (obj.totalCount) paymentMethodsV += parseFloat(obj.totalSum);

          })

          setSumPaymentMethodsQ(paymentMethodsQ)
          setSumPaymentMethodsV(paymentMethodsV)

          await data?.subscriptionsBefore.forEach(obj => {
            sumSubscriptionsBeforeF += parseFloat(obj.plan.price)
          })

          await data?.subscriptionsAfter.forEach(obj => {
            sumSubscriptionsAfterF += parseFloat(obj.plan.price)
          })

          setSumSubscriptionsBefore(parseFloat(sumSubscriptionsBeforeF).toFixed(2))
          setSumSubscriptionsAfter(parseFloat(sumSubscriptionsAfterF).toFixed(2))

          data?.payments.forEach(obj => {
            obj.status === true
              ? sumStatusTrue += parseFloat(obj.value)
              : sumStatusFalse += parseFloat(obj.value);
          })

          setSumStatusTrue(parseFloat(sumStatusTrue).toFixed(2))
          setSumStatusFalse(parseFloat(sumStatusFalse).toFixed(2))

          setLoading(false);
        } catch (err) {
          setLoading(false);
          toastError("Erro: " + err)
        }
      }
      setLoading("false");
      fetchTickets()
    }, 500)
    return () => clearTimeout(delayDebounceFn)

  }
  function SimpleDialog(props) {
    const { onClose, open } = props;

    const handleClose = () => {
      onClose();
    };
    return (
      <Dialog onClose={handleClose} open={open} >
        <DialogTitle>{i18n.t("reports.filters.modal.title")}</DialogTitle>
        <Grid container className={classes.dialog} >
          {(user.profile.toUpperCase() === "ADMIN" && user.profile.toUpperCase() !== "SUPER") ? <Select
            className={classes.dialogItems}
            value={userId}
            onChange={
              (e) => setUserId(e.target.value)}
            style={{ width: '150px' }}
            name="searchUserId"
          >
            {paymentMethods?.map((e) => {
              if (e.profile === "user") {
                return <MenuItem value={e.id} key={e.id}>
                  {e.name}
                </MenuItem>
              } else {
                return null;
              }
            })}
          </Select> : null
          }
          <TextField
            className={classes.dialogItems}
            id="date"
            label={i18n.t("reports.filters.modal.dateInitial")}
            type="date"
            defaultValue={dateIni}
            // className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => setDateIni(e.target.value)}
          />
          <TextField
            id="date"
            label={i18n.t("reports.filters.modal.dateFinal")}
            type="date"
            defaultValue={dateFin}
            className={classes.dialogItems}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => setDateFin(e.target.value)}
          />
          <Button
            variant="contained"
            color="primary"
            className={classes.buttonDialog}
            onClick={
              () => {
                buttonSearchByUserId({ dateInitial: dateIni, dateFinal: dateFin, userId: userId });
                setActive(4);
              }
            }
            disableElevation>
            {i18n.t("reports.filters.modal.button.title")}
          </Button>
        </Grid>
      </Dialog>
    );
  }

  SimpleDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,

  };

  useEffect(() => {
    var init = new Date();
    var final = new Date();
    init.setDate(init.getDate());
    final.setDate(final.getDate());
    // (user.profile.toUpperCase() === "ADMIN" && user.profile.toUpperCase() !== "SUPER") ? buttonSearchByUserId({ dateInitial: init, dateFinal: final }) :
    buttonSearchByUserId({ dateInitial: init, dateFinal: final });
    // eslint-disable-next-line
  }, []);

  const handleDate = (i) => {
    var nowStart = `${new Date().getFullYear()}-${new Date().getMonth() + 1}-01`;
    var nowEnd = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);

    var init = new Date();
    var final = new Date();

    switch (i) {
      case 0:
        init.setDate(init.getDate());
        (user.profile.toUpperCase() === "ADMIN" && user.profile.toUpperCase() !== "SUPER") ? buttonSearchByUserId({ dateInitial: init, dateFinal: final }) :
          buttonSearchByUserId({ dateInitial: init, dateFinal: final });
        break;
      case 1:
        init.setDate(init.getDate() - 7);
        (user.profile.toUpperCase() === "ADMIN" && user.profile.toUpperCase() !== "SUPER") ? buttonSearchByUserId({ dateInitial: init, dateFinal: final }) :
          buttonSearchByUserId({ dateInitial: init, dateFinal: final });
        break;
      case 2:
        init.setDate(init.getDate() - 14);
        (user.profile.toUpperCase() === "ADMIN" && user.profile.toUpperCase() !== "SUPER") ? buttonSearchByUserId({ dateInitial: init, dateFinal: final }) :
          buttonSearchByUserId({ dateInitial: init, dateFinal: final });
        break;
      case 3:
        (user.profile.toUpperCase() === "ADMIN" && user.profile.toUpperCase() !== "SUPER") ? buttonSearchByUserId({ dateInitial: nowStart, dateFinal: nowEnd }) :
          buttonSearchByUserId({ dateInitial: nowStart, dateFinal: nowEnd });
        break;
      case 4:
        init.setDate(init.getDate() - 1); final.setDate(final.getDate() - 1);
        (user.profile.toUpperCase() === "ADMIN" && user.profile.toUpperCase() !== "SUPER") ? buttonSearchByUserId({ dateInitial: init, dateFinal: final }) :
          buttonSearchByUserId({ dateInitial: init, dateFinal: final });
        break;
      // case 4:
      //   (user.profile.toUpperCase() === "ADMIN" && user.profile.toUpperCase() !== "SUPER") ? buttonSearchByUserId({}) :
      //     buttonSearchByUserId({ userId: user.id });
      //   break;
      default:
    }
    setActive(i)
  }

  return (
    <MainContainer>
      <MainHeader>
        {loading && <BackdropLoading />}
        <Grid container className={classes.root} spacing={2}>
          <div className={classes.menu}>
            <Button variant="text" className={active === 0 ? classes.menuButtonsActive : classes.menuButtons} onClick={() => handleDate(0)}>{i18n.t("reports.filters.today")}:</Button>
            <Button variant="text" className={active === 4 ? classes.menuButtonsActive : classes.menuButtons} onClick={() => handleDate(4)}>Ontem</Button>
            <Button variant="text" className={active === 1 ? classes.menuButtonsActive : classes.menuButtons} onClick={() => handleDate(1)}>7 {i18n.t("reports.filters.days")}</Button>
            <Button variant="text" className={active === 2 ? classes.menuButtonsActive : classes.menuButtons} onClick={() => handleDate(2)}>14 {i18n.t("reports.filters.days")}</Button>
            <Button variant="text" className={active === 3 ? classes.menuButtonsActive : classes.menuButtons} onClick={() => handleDate(3)}>Este {i18n.t("reports.filters.month")}</Button>
            <Button variant="text" onClick={handleClickOpen} className={active === 5 ? classes.menuButtonsActive : classes.menuButtons} style={{ fontWeight: "900" }} >
              {i18n.t("reports.filters.filterBy")}:
            </Button>
            <SimpleDialog
              open={open}
              onClose={handleClose}
            />
          </div>
        </Grid>
      </MainHeader>
      <Paper
        className={classes.mainPaper}
        variant="outlined"
      >
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            <Grid item lg={6} xs={12}>
              <Paper className={`${classes.customCardChart} ${classes.customCardChartFixed}`} elevation={3}>
                <div className={classes.customCardsTitle}>RECEBIMENTOS EM {new Date().getFullYear()}**</div>
                <Line options={LineOptions} data={LineData} />
              </Paper>
            </Grid>
            {/* <Grid item lg={6} xs={12}>

              <Paper className={`${classes.customCardChart} ${classes.customCardChartFixed}`} elevation={3}>
                <div className={classes.customCardsTitle}>RECEBIMENTOS</div>
                <div>
                  <Doughnut
                    data={doughnutData}
                    options={doughnutOptions}
                  />
                </div>
              </Paper>
            </Grid> */}


            <Grid item lg={6} xs={12}>
              <Paper className={classes.customCardChart} elevation={3} >
                <div className={classes.customCardsTitle}>RECEBIMENTOS</div>
                <Grid container spacing={3} >
                  <Grid item lg={6} xs={12}>
                    <div style={{ position: "relative" }}>
                      <MonetizationOn fontSize="large" className={`${classes.monetizationOn}`} />
                      <Paper className={`${classes.customCard}`} elevation={3} >
                        <Grid container >
                          <Grid item lg={6} xs={6} className={classes.customCardPrice} >
                            <span>{<PriceBRL value={sumStatusTrue} cifrao={false} />}</span>
                          </Grid>
                          <Grid item lg={6} xs={6} className={classes.customCardText} >
                            <span>{"PAGAMENTOS RECEBIDOS".toUpperCase()}</span>
                          </Grid>
                        </Grid>
                        <div className={`${classes.customCardDescription}`}>{"Assinaturas renovadas pagas*"}</div>
                      </Paper>
                    </div>
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <div style={{ position: "relative" }}>
                      <MonetizationOn fontSize="large" className={`${classes.monetizationOn} ${classes.monetizationOnRed}`} />
                      <Paper className={`${classes.customCard} ${classes.customCardRed}`} elevation={3} >
                        <Grid container style={{ flex: 1, }}>
                          <Grid item lg={6} xs={6} className={classes.customCardPrice} >
                            <span>{<PriceBRL value={sumStatusFalse} cifrao={false} />}</span>
                          </Grid>
                          <Grid item lg={6} xs={6} className={classes.customCardText} >
                            <span>{"PAGAMENTOS PENDENTES".toUpperCase()}</span>
                          </Grid>
                        </Grid>
                        <div className={`${classes.customCardDescription}`}>{"Assinaturas renovadas, mas pendente*"}</div>
                      </Paper>
                    </div>
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <div style={{ position: "relative" }}>
                      <MonetizationOn fontSize="large" className={`${classes.monetizationOn}`} />
                      <Paper className={`${classes.customCard}`} elevation={3} >
                        <Grid container style={{ flex: 1, }}>
                          <Grid item lg={6} xs={6} className={classes.customCardPrice} >
                            <span>{<PriceBRL value={sumSubscriptionsAfter} cifrao={false} />}</span>
                          </Grid>
                          <Grid item lg={6} xs={6} className={classes.customCardText} >
                            <div>{subscriptionsAfter.length}</div>
                            <div>{" EM DIA".toUpperCase()}</div>
                          </Grid>
                        </Grid>
                        <div className={`${classes.customCardDescription}`}>{"Assinaturas Ativas em dia**"}</div>
                      </Paper>
                    </div>
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <div style={{ position: "relative" }}>
                      <MonetizationOn fontSize="large" className={`${classes.monetizationOn} ${classes.monetizationOnRed}`} />
                      <Paper className={`${classes.customCard}`} elevation={3} >
                        <Grid container style={{ flex: 1, }}>
                          <Grid item lg={6} xs={6} className={classes.customCardPrice} >
                            <span>{<PriceBRL value={sumSubscriptionsBefore} cifrao={false} />}</span>
                          </Grid>
                          <Grid item lg={6} xs={6} className={classes.customCardText} >
                            <div>{subscriptionsBefore.length}</div>
                            <div>{" ATRASADOS".toUpperCase()}</div>
                          </Grid>
                        </Grid>
                        <div className={`${classes.customCardDescription}`}>{"Assinaturas Atrasados este ano até agora**"}</div>
                      </Paper>
                    </div>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item lg={12} xs={12}>
              <TableContainer component={Paper} elevation={3} style={{ padding: 5 }}>
                <div className={classes.customCardsTitle}>RECEBIMENTOS POR PLANOS</div>
                <Table className={classes.table} size="small" aria-label="a dense table">
                  <TableHead>
                    <StyledTableRow>
                      <StyledTableCell>Nome do Plano</StyledTableCell>
                      <StyledTableCell align="center">Valor do Plano</StyledTableCell>
                      <StyledTableCell align="center">Assinaturas</StyledTableCell>
                      <StyledTableCell align="center">Totais</StyledTableCell>
                    </StyledTableRow>
                  </TableHead>
                  <TableBody >
                    {paymentMethods.map((rows, i) => (
                      <StyledTableRow
                        key={rows.id}
                        style={i % 2 === 0 ? {} : { backgroundColor: grey[500] }}
                      >
                        {/* <StyledTableCell component="th" scope="row" style={{ borderBottom: "none", padding: 3 }}></StyledTableCell> */}
                        <StyledTableCell align="left">#{rows.id} - {rows.name}</StyledTableCell>
                        <StyledTableCell align="center"><PriceBRL value={rows.price} cifrao={false} /></StyledTableCell>
                        <StyledTableCell align="center">{rows.totalCount}</StyledTableCell>
                        <StyledTableCell align="center">{<PriceBRL value={rows.totalSum} cifrao={false} />}</StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                  <TableHead>
                    <StyledTableRow>
                      <StyledTableCell align="center"><strong>TOTAL</strong></StyledTableCell>
                      <StyledTableCell align="left"></StyledTableCell>
                      <StyledTableCell align="center">{sumPaymentMethodsQ}</StyledTableCell>
                      <StyledTableCell align="center"><PriceBRL value={parseFloat(sumPaymentMethodsV).toFixed(2)} cifrao={false} /></StyledTableCell>
                    </StyledTableRow>
                  </TableHead>
                </Table>
                <div className={`${classes.customCardDescription}`}>{"Pagamentos recebidos por planos*"}</div>
              </TableContainer>
            </Grid>
            <Grid item lg={12} xs={12}>
              <TableContainer component={Paper} elevation={3} style={{ padding: 5 }}>
                <div className={classes.customCardsTitle}>ASSINATURAS POR PLANOS</div>
                <Table className={classes.table} size="small" aria-label="a dense table">
                  <TableHead>
                    <StyledTableRow>
                      <StyledTableCell>Nome do Plano</StyledTableCell>
                      <StyledTableCell align="center">Valor do<br />Plano</StyledTableCell>
                      <StyledTableCell colSpan="2" align="center">Assinaturas<br />Atrasadas</StyledTableCell>
                      <StyledTableCell colSpan="2" align="center">Assinaturas<br />em dia</StyledTableCell>
                    </StyledTableRow>
                  </TableHead>
                  <TableHead>
                    <StyledTableRow>
                      <StyledTableCell colSpan={2}></StyledTableCell>
                      <StyledTableCell align="center">Quant</StyledTableCell>
                      <StyledTableCell align="center">Valor</StyledTableCell>
                      <StyledTableCell align="center">Quant</StyledTableCell>
                      <StyledTableCell align="center">Valor</StyledTableCell>
                    </StyledTableRow>
                  </TableHead>
                  <TableBody >
                    {subscriptionsByPlan?.map((rows, i) => (
                      <StyledTableRow
                        key={rows.id}
                        style={i % 2 === 0 ? {} : { backgroundColor: grey[500] }}
                      >
                        {/* <StyledTableCell component="th" scope="row" style={{ borderBottom: "none", padding: 3 }}></StyledTableCell> */}
                        <StyledTableCell align="left">#{rows.id} - {rows.name}</StyledTableCell>
                        <StyledTableCell align="center"><PriceBRL value={rows.price} cifrao={false} /></StyledTableCell>
                        <StyledTableCell align="center">{rows.totalSubscriptionAfter} </StyledTableCell>
                        <StyledTableCell align="center">{<PriceBRL value={parseFloat(rows.totalSubscriptionAfter * rows.price).toFixed(2)} cifrao={false} />} </StyledTableCell>
                        <StyledTableCell align="center">{rows.totalSubscriptionBefore} </StyledTableCell>
                        <StyledTableCell align="center">{<PriceBRL value={parseFloat(rows.totalSubscriptionBefore * rows.price).toFixed(2)} cifrao={false} />} </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                  <TableHead>
                    <StyledTableRow>
                      <StyledTableCell colSpan="2" align="center"><strong>TOTAL</strong></StyledTableCell>
                      <StyledTableCell align="center">{sumTotalSubscriptionByPlan["totalSubscriptionAfterQ"]}</StyledTableCell>
                      <StyledTableCell align="center">{<PriceBRL value={parseFloat(sumTotalSubscriptionByPlan["totalSubscriptionAfterV"]).toFixed(2)} cifrao={false} />}</StyledTableCell>
                      <StyledTableCell align="center">{sumTotalSubscriptionByPlan["totalSubscriptionBeforeQ"]}</StyledTableCell>
                      <StyledTableCell align="center">{<PriceBRL value={parseFloat(sumTotalSubscriptionByPlan["totalSubscriptionBeforeV"]).toFixed(2)} cifrao={false} />}</StyledTableCell>
                    </StyledTableRow>
                  </TableHead>
                </Table>
                <div className={`${classes.customCardDescription}`}>{"Pagamentos recebidos por planos**"}</div>
              </TableContainer>
            </Grid>
          </Grid>
        </Container>
        <Typography variant="body1" style={{ margin: "10px", borderBottom: "1px solid grey" }}>
          "*Dados baseado nos filtros | **Dados base início do ano até agora"
        </Typography>
      </Paper>
    </MainContainer >
  )
}

export default Dashboard