import React, { useState, useContext } from "react";

import { Hidden, IconButton, makeStyles, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";

import { WhatsApp, PlaylistAddCheck, CalendarToday, Person, Assignment, MonetizationOn, Edit, CheckCircle } from "@material-ui/icons";

import { i18n } from "../../translate/i18n";
import moment from 'moment';
import PriceBRL from "../PriceBRL";
import PaymentModal from "../PaymentModal";
import { MsServersContext } from "../../context/MsServers/MsServersContext";
import api from "../../services/api";

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: "5px",
  },
  cardHeader: {
    borderRadius: "5px",
    backgroundColor: "#F2F2F2",
    border: "1px solid #F2F2F2",
  },
  cardHeaderAssignmenter: {
    border: "1px solid RoyalBlue",
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  infoTags: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "right",
    position: "absolute",
    bottom: 3,
    right: 3,
  },
  infoTagsData: {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
    alignItems: "left",
    bottom: 0,
    right: 3,

  },
  infoTag: {
    cursor: "pointer",
    display: "flex",
    color: "gray",
    paddingTop: 3,
    paddingLeft: 5,
    marginRight: 1,
    borderRadius: 3,
  },
  infoTagExpire72: {
    color: "#FEDE00",
  },
  infoTagExpire24: {
    color: "#FF8C00",
  },
  infoTagExpired: {
    color: "red",
  },
  infoTagRed: {
    fontWeight: "600",
    background: theme.palette.secondary.main,
    border: "1px solid " + theme.palette.secondary.main,
    color: "#FFF",
    padding: 1,
    paddingTop: 2,
    paddingLeft: 6,
    paddingRight: 6,
    borderRadius: 15,
  },
  infoTagBlue: {
    color: "RoyalBlue",
  },
  infoTagGreen: {
    color: "#00a859",
  },
  infoButton: {
    paddingRight: 5,
    cursor: "pointer",
  },

  tableRow: {
    borderBotton: "1 solid silver",
    "&.Mui-selected, &.Mui-selected:hover": {
      backgroundColor: "#D0F0C0",
      "& > .MuiTableCell-root": {
        color: theme.mode === 'dark' ? "#000" : "",
        fontWeight: 600
      }
    }
  }
}));


const PaymentListTable = ({ payments, handleDrawerOpen, modules, contactId }) => {

  const classes = useStyles();
  const [selectedId, setSelectedId] = useState(null);
  const { msServers } = useContext(MsServersContext);
  const [paymentModal, setPaymentModal] = useState(false);
  const [subscriptionsData, setSubscriptionsData] = useState([]);
  const [selectdContact, setSelectdContact] = useState(null);


  const handleOpenPaymentModal = async (c) => {
    const { data } = await api.get(`/subscription/listByContactId/${c}`);
    setSelectdContact(c)
    setSubscriptionsData(data);
		setPaymentModal(true);
	};

  return (

    <Table size="small" sx={{ minWidth: 650 }} aria-label="a dense table">
      <PaymentModal
        modalOpen={paymentModal}
        onClose={(e) => setPaymentModal(false)}
        contactId={selectdContact}
        modules={modules}
        msServers={msServers}
        subscriptionsData={subscriptionsData}
        paymentId={selectedId}
      />
      <TableHead>
        <TableRow style={{ borderBotton: "1 solid silver" }}>
          <Hidden lgUp>
            <TableCell align="left" style={{ borderRight: "1 solid silver" }}>
              Dados
            </TableCell>
            <TableCell align="left">
              {i18n.t("global.actions")}
            </TableCell>
          </Hidden>
          <Hidden mdDown>
            <TableCell align="left">
              {i18n.t("global.name")} / {i18n.t("global.number")}
            </TableCell>
            <TableCell align="left">
              <PlaylistAddCheck fontSize="small" /> {i18n.t("global.paymentMethod")}
            </TableCell>
            <TableCell align="left">
              <MonetizationOn fontSize="small" /> {i18n.t("global.value")}
            </TableCell>
            <TableCell align="center">
              <CalendarToday fontSize="small" /> {i18n.t("global.payDate")}
            </TableCell>
            <TableCell align="center">
              {i18n.t("global.actions")}
            </TableCell>
          </Hidden>
        </TableRow>
      </TableHead>
      <TableBody>
        <>
          {payments?.map((payment) => (

            <TableRow
              key={payment.paymentId}
              selected={selectedId === payment.paymentId}
              className={classes.tableRow}
            >
              <Hidden lgUp>
                <TableCell align="left" style={{ borderRight: "1 solid silver" }}>
                  <Person className={`${classes.infoButton} ${classes.infoTagBlue}`} /> {payment?.payment?.contact?.name}<br />
                  <WhatsApp className={`${classes.infoButton} ${classes.infoTagGreen}`} /> {payment?.payment?.contact?.number}<br />

                  <PlaylistAddCheck className={`${classes.infoButton} ${classes.infoTagBlue}`} /> {payment.paymentMethod?.name} | <PriceBRL value={payment.value} />
                  <br />
                  <div
                    className={classes.infoTagMany} >
                    <CalendarToday className={`${classes.infoButton}`} />
                    {payment.date ? moment(payment.date).format('DD/MM/YY HH:mm') : ""}
                  </div>

                </TableCell>
              </Hidden>
              <Hidden mdDown>
                <TableCell align="left" style={{ borderRight: "1 solid silver" }}>
                  {payment?.payment?.contact?.name} ({payment?.payment?.contact?.number})
                </TableCell>
                <TableCell align="left">
                  {payment.paymentMethod?.name} <br />
                </TableCell>
                <TableCell align="left">
                  <PriceBRL value={payment.value} />
                </TableCell>
                <TableCell align="center">
                  {payment.date ? moment(payment.date).format('DD/MM/YY HH:mm') : ""}
                </TableCell>
              </Hidden>
              <TableCell align="center">
                <IconButton
                  size="small"
                  onClick={() => {setSelectedId(payment.paymentId); handleOpenPaymentModal(payment?.payment?.contact?.id); handleDrawerOpen(payment?.payment?.contact, payment.paymentId)}}
                >
                  <Edit color="primary" />
                </IconButton>
                <IconButton
                  size="small"
                  onClick={() => { setSelectedId(payment.paymentId); handleDrawerOpen(payment?.payment?.contact, payment.paymentId) }}
                >
                  <Assignment style={{ color: "RoyalBlue" }} />
                </IconButton>

              </TableCell>
              <TableCell align="center">
                  {payment?.payment?.status
                    ? <CheckCircle style={{ color: "green" }} />
                    : <CheckCircle style={{ color: "red" }} />
                  }
                </TableCell>
            </TableRow>
          ))}
        </>
      </TableBody>
    </Table>
  );

};

export default PaymentListTable;
