import React, { useState, useEffect, useContext } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  DialogActions,
  CircularProgress,
  TextField,
  FormControl,
  FormControlLabel,
  Switch,
  InputLabel,
  Select,
  MenuItem
} from "@material-ui/core";

import { Autocomplete } from "@material-ui/lab";

import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";
import { isArray } from "lodash";
import moment from 'moment';
import { MsServersContext } from "../../context/MsServers/MsServersContext";

const useStyles = makeStyles((theme) => ({
  root: { display: "flex", flexWrap: "wrap", },

  multFieldLine: {
    display: "flex",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },

  btnWrapper: {
    position: "relative",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const SubscriptionModal = ({ open, onClose, contact, subscriptionId, modules }) => {
  const classes = useStyles();

  const initialState = {
    contactId: "",
    planId: "",
    msServerId: "",
    sUser: "",
    sPass: "",
    description: "",
    expiresAt: moment().format('YYYY-MM-DDTHH:mm'),
    status: true,
  };
  const [subscription, setSubscription] = useState(initialState);
  const [contacts, setContacts] = useState([]);
  const [plans, setPlans] = useState([]);
  const [selectedContact, setSelectedContact] = useState("");
  const { msServers } = useContext(MsServersContext);


  const SessionSchema = Yup.object().shape({
    contactId: Yup.string().when((schema) => {
      if (!selectedContact)
        return Yup.string().required((i18n.t("validation.required")));
    }),
    planId: Yup.string().required(i18n.t("validation.required")),
    expiresAt: Yup.string().required(i18n.t("validation.required")),

  });


  const changeDate = async (e) => {
    const valueString = new Date(e.expiresAt);
    const newValue = moment(valueString).format('YYYY-MM-DDTHH:mm');
    setSelectedContact(e.contact);
    setSubscription({ ...e, 'expiresAt': newValue });

  };

  useEffect(() => {

    const delayDebounceFn = setTimeout(() => {

      if (!subscriptionId) {
        setSelectedContact(contact);
      }
      const fetchContacts = async () => {
        try {
          const { data } = await api.get("contacts/list");
          setContacts(data);

        } catch (err) {
          toastError(err);
        }
      };
      // eslint-disable-next-line
      { open === true && fetchContacts(); }
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [open, subscriptionId, contact]);

  useEffect(() => {
    try {
      (async () => {

        const { data } = await api.get('/plan/list');
        let customList = data.map((c) => ({ id: c.id, name: c.name, number: c.number }));
        if (isArray(customList)) {
          setPlans([{ id: "", name: "", number: "" }, ...customList]);
        }

      })()
    } catch (err) {
      toastError(err);
    }
  }, [open]);

  useEffect(() => {
    const fetchSession = async () => {
      if (!subscriptionId) return;

      try {
        const { data } = await api.get(`subscription/${subscriptionId}`);
        // eslint-disable-next-line
        changeDate(data);

      } catch (err) {
        toastError(err);
      }
    };
    fetchSession();
    // eslint-disable-next-liue
  }, [subscriptionId]);

  const handleSave = async (values) => {
    try {

      let subscriptionData = { ...values, 'contactId': selectedContact.id }

      if (modules.modulesStreaming !== "enabled") {
        subscriptionData = { ...subscriptionData, 'msServerId': null, 'sUser': null, 'sPass': null }
      }
      if (subscriptionId) {
        await api.put(`/subscription/${values.id}`, subscriptionData);
      } else {
        await api.post("/subscription", subscriptionData);
      }
      toast.success(i18n.t("global.successSave"));
      handleClose();

    } catch (err) {
      toastError(err);
    }

  };

  const handleSetSelectedContact = (newValue) => {
    setSelectedContact(newValue)
  }
  const handleClose = () => {
    onClose();
    setSubscription(initialState);
    setSelectedContact(null);
  };

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        scroll="paper"
      >
        <DialogTitle>
          {subscriptionId
            ? `${i18n.t("global.buttons.edit")} ${i18n.t("subscription.name")}`
            : `${i18n.t("global.buttons.add")} ${i18n.t("subscription.name")}`
          }
        </DialogTitle>
        <Formik
          initialValues={subscription}
          enableReinitialize={true}
          validationSchema={SessionSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSave(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ values, touched, errors, isSubmitting }) => (

            <Form>
              <DialogContent dividers>
                <FormControl variant="outlined" fullWidth margin="dense">
                  <Autocomplete
                    autoHighlight
                    name="contactId"
                    id="contactId"
                    value={selectedContact}
                    defaultValue={selectedContact}
                    options={contacts}
                    // isOptionEqualToValue={(option, value) => option.id === value.id}
                    getOptionLabel={({ name, number }) => {
                      return `${name} (${number})`;
                    }}
                    renderOption={(option) => <span>{`${option.name} (${option.number})`}</span>}
                    renderInput={(params) =>
                      <Field
                        type="text"
                        as={TextField}
                        autoFocus
                        variant="outlined"
                        name="contactId"
                        {...params}
                        label={i18n.t("global.subscription")}
                        placeholder={i18n.t("global.subscription")}
                        error={touched.contactId && Boolean(errors.contactId)}
                        helpertext={touched.contactId && errors.contactId}
                      />
                    }
                    onChange={(e, newValue) => handleSetSelectedContact(newValue)}
                  />
                </FormControl>
                <FormControl variant="outlined" margin="dense" fullWidth>
                  <InputLabel>{i18n.t("global.plan")}</InputLabel>
                  <Field
                    as={Select}
                    name="planId"
                    color="primary"
                    label={i18n.t("global.plan")}
                    error={touched.planId && Boolean(errors.planId)}
                    helpertext={touched.planId && errors.planId}
                  >
                    {plans.map((plan) => (
                      <MenuItem key={plan.id} value={plan.id}>{plan.name}</MenuItem>
                    ))}

                  </Field>
                </FormControl>

                {modules.modulesStreaming === "enabled" &&
                  <>
                    <FormControl variant="outlined" margin="dense" fullWidth>
                      <InputLabel>{i18n.t("global.server")}</InputLabel>
                      <Field
                        as={Select}
                        name="msServerId"
                        color="primary"
                        label={i18n.t("global.server")}
                        error={touched.msServerId && Boolean(errors.msServerId)}
                        helpertext={touched.msServerId && errors.msServerId}
                      >
                        {msServers.map((msServer) => (
                          <MenuItem key={msServer.id} value={msServer.id}>{msServer.name}</MenuItem>
                        ))}
                      </Field>
                      <Field
                        as={TextField}
                        name="sUser"
                        id="sUser"
                        variant="outlined"
                        margin="dense"
                        label={i18n.t("global.user")}
                        error={touched.sUser && Boolean(errors.sUser)}
                        helpertext={touched.sUser && errors.sUser}
                      />
                      <Field
                        as={TextField}
                        name="sPass"
                        id="sPass"
                        variant="outlined"
                        margin="dense"
                        label={i18n.t("global.pass")}
                        error={touched.sPass && Boolean(errors.sPass)}
                        helpertext={touched.sPass && errors.sPass}
                      />
                    </FormControl>
                  </>
                }

                <div>
                  <FormControl variant="outlined" margin="dense" fullWidth>
                    <Field
                      type="datetime-local"
                      as={TextField}
                      name="expiresAt"
                      variant="outlined"
                      margin="dense"
                      label={i18n.t("global.expiresAt")}
                      error={touched.expiresAt && Boolean(errors.expiresAt)}
                      helperText={touched.expiresAt && errors.expiresAt}
                    />
                  </FormControl>
                </div>
                <div>
                  <FormControl variant="outlined" margin="dense" fullWidth>
                    <Field
                      as={TextField}
                      label={i18n.t("global.description")}
                      multiline
                      minRows={5}
                      name="description"
                      variant="outlined"
                      margin="dense"
                    />
                  </FormControl>
                </div>

              </DialogContent>
              <DialogActions>
                <div className={classes.multFieldLine} >
                  <FormControlLabel
                    control={
                      <Field
                        as={Switch}
                        color="primary"
                        name="status"
                        checked={values.status}
                      />
                    }
                    label="Status"
                  />
                </div>
                <Button
                  onClick={handleClose}
                  color="secondary"
                  disabled={isSubmitting}
                  variant="outlined"
                >
                  {i18n.t("global.buttons.cancel")}
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={isSubmitting}
                  variant="contained"
                  className={classes.btnWrapper}
                >
                  {subscriptionId
                    ? i18n.t("global.buttons.okEdit")
                    : i18n.t("global.buttons.okAdd")}
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default React.memo(SubscriptionModal);
