import React, { useContext, useState } from "react";

import { Hidden, IconButton, makeStyles, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";

import { WhatsApp, Computer, PlaylistAddCheck, CalendarToday, Person, LockOpen, Assignment, MonetizationOn, CheckCircle } from "@material-ui/icons";

import { i18n } from "../../translate/i18n";
import moment from 'moment';
import clsx from "clsx";
import PriceBRL from "../PriceBRL";
import { MsServersContext } from "../../context/MsServers/MsServersContext";


const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: "5px",
  },
  cardHeader: {
    borderRadius: "5px",
    backgroundColor: "#F2F2F2",
    border: "1px solid #F2F2F2",
  },
  cardHeaderAssignmenter: {
    border: "1px solid RoyalBlue",
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  infoTags: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "right",
    position: "absolute",
    bottom: 3,
    right: 3,
  },
  infoTagsData: {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
    alignItems: "left",
    bottom: 0,
    right: 3,

  },
  infoTag: {
    cursor: "pointer",
    display: "flex",
    color: "gray",
    paddingTop: 3,
    paddingLeft: 5,
    marginRight: 1,
    borderRadius: 3,
  },
  infoTagExpire72: {
    color: "#FEDE00",
  },
  infoTagExpire24: {
    color: "#FF8C00",
  },
  infoTagExpired: {
    color: "red",
  },
  infoTagRed: {
    fontWeight: "600",
    background: theme.palette.secondary.main,
    border: "1px solid " + theme.palette.secondary.main,
    color: "#FFF",
    padding: 1,
    paddingTop: 2,
    paddingLeft: 6,
    paddingRight: 6,
    borderRadius: 15,
  },
  infoTagBlue: {
    color: "RoyalBlue",
  },
  infoTagGreen: {
    color: "#00a859",
  },
  infoButton: {
    paddingRight: 5,
    cursor: "pointer",
  },

  tableRow: {
    borderBotton: "1 solid silver",
    "&.Mui-selected, &.Mui-selected:hover": {
      backgroundColor: "#D0F0C0",
      // "& > .MuiTableCell-root": {
      //   color: "white"
      // }
    }
  }
}));


const SubscriptionListTable = ({ subscriptions, handleDrawerOpen, modules }) => {

  const classes = useStyles();
  const { msServers } = useContext(MsServersContext);
  const [selectedId, setSelectedId] = useState(null);


  const expireHours = (a) => {
    const b = moment(a).diff(moment(), 'hours')
    return b;
  }


  const msServerName = (msServerId) => {
    const msServersFilter = msServers?.filter(function (e) { return e.id === msServerId; })
    const msServersName = msServersFilter.length > 0 ? msServersFilter[0].name : "";
    return msServersName;
  }

  return (
    <Table size="small" sx={{ minWidth: 650 }} aria-label="a dense table">
      <TableHead>
        <TableRow style={{ borderBotton: "1 solid silver" }}>
          <Hidden lgUp>
            <TableCell align="left" style={{ borderRight: "1 solid silver" }}>
              Dados
            </TableCell>
            <TableCell align="left">
              {i18n.t("global.actions")}
            </TableCell>
          </Hidden>
          <Hidden mdDown>
            <TableCell align="left">
              {i18n.t("global.name")}
            </TableCell>
            <TableCell align="left">
              {i18n.t("global.plan")}
            </TableCell>
            {modules.modulesStreaming === "enabled" &&
              <>
                <TableCell align="left">
                  {i18n.t("global.server")}
                </TableCell>
                <TableCell align="left">
                  {i18n.t("subscription.userData")}
                </TableCell>
              </>
            }
            <TableCell align="center">
              {i18n.t("global.expiresAt")}
            </TableCell>
            <TableCell align="center">
              Status
            </TableCell>
            <TableCell align="left">
              {i18n.t("global.actions")}
            </TableCell>
          </Hidden>
        </TableRow>
      </TableHead>
      <TableBody>
        <>
          {subscriptions?.map((subscription) => (

            <TableRow
              key={subscription.id}
              selected={selectedId === subscription.id}
              className={classes.tableRow}
            >

              <TableCell align="left" style={{ borderRight: "1 solid silver" }}>
                <Person className={`${classes.infoButton} ${classes.infoTagBlue}`} /> {subscription.contact?.name}<br />
                <WhatsApp className={`${classes.infoButton} ${classes.infoTagGreen}`} /> {subscription.contact?.number}<br />                
                <Hidden lgUp>
                <PlaylistAddCheck className={`${classes.infoButton} ${classes.infoTagBlue}`} /> {subscription.plan?.name} | <PriceBRL value={subscription.plan?.price} />
                  <br />
                  {modules.modulesStreaming === "enabled" &&
                    <>
                      <Computer className={`${classes.infoButton} ${classes.infoTagBlue}`} /> {msServerName(subscription.msServerId)}<br />
                      <Person className={`${classes.infoButton} ${classes.infoTagBlue}`} /> {subscription.sUser}<br />
                      <LockOpen className={`${classes.infoButton} ${classes.infoTagGreen}`} /> {subscription.sPass}<br />
                    </>
                  }
                  <div
                    className={clsx(`${classes.infoTagMany}`, {
                      [classes.infoTagGreen]: expireHours(subscription.expiresAt) > 72,
                      [classes.infoTagExpire72]: expireHours(subscription.expiresAt) <= 72,
                      [classes.infoTagExpire24]: expireHours(subscription.expiresAt) <= 24,
                      [classes.infoTagExpired]: expireHours(subscription.expiresAt) <= 0,
                    })}
                  >
                    <CalendarToday className={`${classes.infoButton}`} />
                    <strong>{subscription.expiresAt ? moment(subscription.expiresAt).format('DD/MM/YY HH:mm') : ""}</strong>
                  </div>
                </Hidden>
              </TableCell>
              <Hidden mdDown>
                <TableCell align="left">
                  <PlaylistAddCheck className={`${classes.infoButton} ${classes.infoTagBlue}`} /> {subscription.plan?.name} <br />
                  <MonetizationOn className={`${classes.infoButton} ${classes.infoTagBlue}`} /> <PriceBRL value={subscription.plan?.price} />
                </TableCell>
                {modules.modulesStreaming === "enabled" &&
                  <>
                    <TableCell align="left">{msServerName(subscription.msServerId)}</TableCell>
                    <TableCell align="left">
                      <Person className={`${classes.infoButton} ${classes.infoTagBlue}`} /> {subscription.sUser}<br />
                      <LockOpen className={`${classes.infoButton} ${classes.infoTagGreen}`} /> {subscription.sPass}
                    </TableCell>
                  </>
                }
                <TableCell align="center">

                  <div
                    className={clsx(`${classes.infoTagMany}`, {
                      [classes.infoTagGreen]: expireHours(subscription.expiresAt) > 72,
                      [classes.infoTagExpire72]: expireHours(subscription.expiresAt) <= 72,
                      [classes.infoTagExpire24]: expireHours(subscription.expiresAt) <= 24,
                      [classes.infoTagExpired]: expireHours(subscription.expiresAt) <= 0,
                    })}
                  >
                    <CalendarToday className={`${classes.infoButton}`} />
                    <strong>{subscription.expiresAt ? moment(subscription.expiresAt).format('DD/MM/YY HH:mm') : ""}</strong>
                  </div>
                </TableCell>
                <TableCell align="center">
                  {subscription.status
                    ? <CheckCircle style={{ color: "green" }} />
                    : <CheckCircle style={{ color: "grey" }} />
                  }
                </TableCell>
              </Hidden>
              <TableCell align="center">

                <IconButton
                  size="small"
                  onClick={() => { setSelectedId(subscription.id); handleDrawerOpen(subscription.contact, subscription.id) }}
                >
                  <Assignment style={{ color: "RoyalBlue" }} />
                </IconButton>

              </TableCell>

            </TableRow>
          ))}
        </>
      </TableBody>
    </Table>
  );

};

export default SubscriptionListTable;
