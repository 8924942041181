import React, { useState, useContext, useRef } from "react";

import {
  makeStyles,
  Toolbar,
  Typography,
  IconButton,
  Box,
  Popover,
  Switch,
  Button
} from "@material-ui/core";

import { Brightness4, BrightnessHigh } from "@material-ui/icons";
import { i18n } from "../../translate/i18n";

import ColorModeContext from "../../layout/themeContext";
import ColorPicker from "../../components/ColorPicker";
import HexToRgb from "../../components/HexToRgb";
import Login from "./Login";

const drawerWidth = 100

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    background: `linear-gradient(to right, 
      ${theme.palette.primary.main},  
      rgba(
        ${HexToRgb(theme.palette.primary.main, true).r}, 
        ${HexToRgb(theme.palette.primary.main, true).g},
        ${HexToRgb(theme.palette.primary.main, true).b}, 
      0.8)
       )`,
  },
  toolbar: {
    flex: 1,
    display: "flex",
    justifyContent: "space-between",
    paddingLeft: 5,
    paddingRight: 2,
    maxHeight: 47,
    color: "#fff"
  },
  toolbarLogo: {
    marginTop: 5,
    marginLeft: 17,
    alignItems: "center",
    cursor: "pointer",
  },
  appBar: {
    margin: 0,
    left: 0,
    right: 0,
    paddingLeft: 10,
    width: "unset",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 5,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
    color: theme.palette.primary.contrastText,
  },
  appBarSpacer: {
    margin: 10,
    display: "flex",
    height: "calc(100vh - 68px)",
    flexDirection: "column",
    overflow: "hidden",
    borderRadius: 10,
    padding: 10,
    border: "1px solid " + theme.palette.primary.main,
    background: `linear-gradient(to right, 
      rgba(
      ${HexToRgb(theme.palette.primary.contrastText, true).r}, 
      ${HexToRgb(theme.palette.primary.contrastText, true).g},
      ${HexToRgb(theme.palette.primary.contrastText, true).b}, 
    0.5),
    rgba(
      ${HexToRgb(theme.palette.primary.contrastText, true).r}, 
      ${HexToRgb(theme.palette.primary.contrastText, true).g},
      ${HexToRgb(theme.palette.primary.contrastText, true).b}, 
    0.5)
     )`,
  },
  content: {
    flex: 1,
    overflow: "auto",
  },
  userName: {
    fontSize: "75%",
    paddingLeft: "5px",
    color: theme.palette.primary.contrastText,
  },
  colorText: {
    color: theme.palette.primary.contrastText,
  },

  popoverPaper: {
    width: "100%",
    maxWidth: 350,
    marginTop: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      maxWidth: 270,
    },
    marginBottom: 5,
    padding: 5,
    background: theme.palette.primary.main,


  },
  buttonDarkmode: {
    color: theme.palette.primary.contrastText,
    border: "1px solid " + theme.palette.primary.contrastText,
  },
}));

const LoggedInLayout = ({ children }) => {
  const classes = useStyles();
  const { colorMode } = useContext(ColorModeContext);
  const [isOpen, setIsOpen] = useState(false);
  const anchorElTheme = useRef();

  const [colorPickerModalOpen, setColorPickerModalOpen] = useState(false);

  const [mode, setMode] = useState(window.localStorage.getItem("mode"));
  const [colorTheme, setColorTheme] = useState(window.localStorage.getItem("colorTheme"));

  const handleTheme = () => {
    setIsOpen(prevState => !prevState);
  };

  const handleThemeAway = () => {
    setIsOpen(false);
  };
  const handleThemeChoose = () => {
    const theme = (mode === "dark" ? "light" : "dark");
    window.localStorage.setItem("mode", theme);
    setMode(theme)
    colorMode.toggleColorMode(theme);
    setIsOpen(false);
  };
  const handleColorChoose = (color) => {
    colorMode.setColorTheme(color);
    setColorTheme(color)
    window.localStorage.setItem("colorTheme", color)
  };

  return (
    <div className={classes.root}>

      <main className={classes.content}>
        <Box
          className={classes.appBar}
        >
          <Toolbar variant="dense" className={classes.toolbar}>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              className={classes.title}
            >
              {i18n.t("global.welcome")}
            </Typography>

            {/* {user.id && <NotificationsPopOver />} */}

            <div>
              {/* CHOOSE THEME */}
              <IconButton
                onClick={handleTheme}
                ref={anchorElTheme}
                aria-label="Open Notifications"
                color="inherit"
                className={classes.colorText}
              >
                {mode === "dark" ? <Brightness4 /> : <BrightnessHigh />}
              </IconButton>
              <Popover
                disableScrollLock
                open={isOpen}
                anchorEl={anchorElTheme.current}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                classes={{ paper: classes.popoverPaper }}
                onClose={handleThemeAway}
              >
                <Box
                  top={0}
                  left={0}
                  bottom={1}
                  right={0}
                  paddingTop={1}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => handleThemeChoose()}
                    className={classes.buttonDarkmode}
                  >
                    <Switch checked={mode === "dark" ? true : false} /> <Typography variant="body1" component="div" > {mode === "dark" ? " Desativar modo Dark" : " Ativar modo Dark"}</Typography>
                  </Button>
                </Box>
                <ColorPicker
                  open={colorPickerModalOpen}
                  handleClose={() => setColorPickerModalOpen(false)}
                  onChange={(color) => { handleColorChoose(color) }}
                  noDialog={true}
                  currentColor={colorTheme}
                />
              </Popover>
              {/* CHOOSE THEME */}

            </div>
          </Toolbar>
        </Box>
        <div className={classes.appBarSpacer}>
          <Login colorTheme={colorTheme} />
        </div>
      </main>
    </div>
  );
};

export default LoggedInLayout;
