import React, { useContext, useEffect, useRef, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";

import {
  Paper,
  InputBase,
  Badge,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  FormControlLabel,
  Switch,
  IconButton
} from "@material-ui/core";

import {
  Search,
  AddCircle,
  FilterList,
  Close,
  ArrowDropDown
} from "@material-ui/icons";

import NewTicketModal from "../NewTicketModal";
import TicketsList from "../TicketsList";
import TabPanel from "../TabPanel";
import { UsersFilter } from "../UsersFilter";

import CustomToolTip from "../ToolTips";

import { i18n } from "../../translate/i18n";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../Can";
import TicketsQueueSelect from "../TicketsQueueSelect";


const useStyles = makeStyles((theme) => ({
  ticketsWrapper: {
    position: "relative",
    display: "flex",
    height: "100%",
    flexDirection: "column",
    overflow: "hidden",
    borderRadius: 10,
    border: "1px solid " + theme.palette.primary.main,
  },
  ticketsList: {
    position: "relative",
    display: "flex",
    height: "100%",
    flexDirection: "column",
    overflow: "hidden",

  },

  ticketOptionsBox: {
    display: "flex",
    justifyContent: "right",
    alignItems: "right",
    background: "transparent",
    padding: theme.spacing(1),
    paddingBottom: 10,
    marginTop: 10
  },

  serachInputWrapper: {
    flex: 1,
    border: "2px solid " + theme.palette.primary.main,
    display: "flex",
    borderRadius: 10,
    padding: 4,
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  tabIcon: {
    borderRadius: 5,
    height: "36px",
    minWidth: "36px",
    padding: "5px",
    cursor: "pointer",
    alignSelf: "center",
    background: "rgba(0, 0, 0, 0.05)",
    marginLeft: 3,
    marginRight: 3,
    border: 'none',
    borderBottom: '2px solid grey',
  },
  tabIconArrowHidden: {
    display: 'none',
    marginTop: -10,
    marginLeft: '50%',
    marginRight: '50%',
  },
  tabIconOpen: {
    border: 'none',
    borderBottom: '3px solid ' + theme.palette.primary.main,
  },
  tabIconOpenArrow: {
    display: 'block',
    color: theme.palette.primary.main,
  },
  ticketListOpen: {
    borderTop: '2px solid ' + theme.palette.primary.main,
  },
  tabIconPending: {
    border: 'none',
    borderBottom: '3px solid #EEAD2D',
  },
  tabIconPendingArrow: {
    display: 'block',
    color: '#EEAD2D',
  },
  ticketListPending: {
    borderTop: '2px solid #EEAD2D',
  },
  tabIconClosed: {
    border: 'none',
    borderBottom: '3px solid ' + theme.palette.secondary.main,
    paddingTop: "7px",
  },
  tabIconClosedArrow: {
    display: 'block',
    color: theme.palette.secondary.main,
  },
  ticketListClosed: {
    borderTop: '2px solid ' + theme.palette.secondary.main,
  },
  tabIconSearch: {
    border: 'none',
    borderBottom: '3px solid #000',
  },
  tabIconSearchArrow: {
    display: 'block',
    color: '#000',
  },
  ticketListSearch: {
    borderTop: '2px solid #000',
  },
  tabIconSelect: {
    paddingLeft: "10px",
    paddingTop: "10px",
    paddingRight: "5px",
    marginLeft: 3,
    marginRight: 3,
    fontWeight: 500,
  },
  badge: {
    paddingRight: "5px",
    top: -5
  },
  search: {
    marginLeft: 6,
    marginRight: 6,
    alignSelf: "center",
  },

  searchInput: {
    flex: 1,
    border: "none",
    borderRadius: 10,
  },

  show: {
    display: "block",
  },
  hide: {
    display: "none !important",
  },
  searchTitle: {
    padding: theme.spacing(2, 2, 0),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const TicketsManager = () => {
  const classes = useStyles();

  const [searchParam, setSearchParam] = useState("");
  const [tab, setTab] = useState("open");
  const [tabOpen, setTabOpen] = useState("open");
  const [newTicketModalOpen, setNewTicketModalOpen] = useState(false);
  const [showAllTickets, setShowAllTickets] = useState(false);
  const searchInputRef = useRef();
  const { user } = useContext(AuthContext);

  const [openCount, setOpenCount] = useState(0);
  const [pendingCount, setPendingCount] = useState(0);

  const userQueueIds = user.queues.map((q) => q.id);
  const [selectedQueueIds, setSelectedQueueIds] = useState(userQueueIds || []);

  const [openOptions, setOpenOptions] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);


  const handleOpenOptions = () => {
    setOpenOptions(true);
  };

  const handleCloseOptions = () => {
    setOpenOptions(false);
  };

  useEffect(() => {
    if (user.profile.toUpperCase() === "ADMIN" || user.profile.toUpperCase() === "SUPER") {
      setShowAllTickets(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let searchTimeout;


  const handleSearch = (e) => {
    const searchedTerm = e.target.value.toLowerCase();

    clearTimeout(searchTimeout);
    setSearchParam(searchedTerm);

    searchTimeout = setTimeout(() => {
      setSearchParam(searchedTerm);
    }, 800);
  };


  const handleChangeTabOpenChild = newValue => {
    setTabOpen(newValue);
  };


  const applyPanelStyle = (status) => {
    if (tabOpen !== status) {
      return { width: 0, height: 0 };
    }
  };

  const handleSelectedUsers = (selecteds) => {
    const users = selecteds.map((t) => t.id);
    setSelectedUsers(users);
  };

  return (

    <Paper elevation={0} variant="outlined" className={classes.ticketsWrapper}>
      <Dialog maxWidth={"md"} fullWidth={"true"} open={openOptions} onClose={handleCloseOptions}>

        <DialogTitle>
          <Typography variant="h6">{i18n.t("global.filter")}s</Typography>
          <IconButton aria-label="close" className={classes.closeButton} onClick={handleCloseOptions}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Can
            role={user.profile}
            perform="tickets-manager:showall"
            yes={() => (
              <>
                <FormControlLabel
                  label={i18n.t("tickets.buttons.showAll")}
                  labelPlacement="start"
                  control={
                    <Switch
                      size="small"
                      checked={showAllTickets}
                      onChange={() =>
                        setShowAllTickets((prevState) => !prevState)
                      }
                      name="showAllTickets"
                      color="primary"
                    />
                  }
                />
                {(user.profile.toUpperCase() === "ADMIN" || user.profile.toUpperCase() === "SUPER") && (
                  <UsersFilter
                    onFiltered={handleSelectedUsers}
                    selectedUsers={selectedUsers}
                    onChange={(values) => setSelectedUsers(values)}
                  />
                )}
              </>
            )}
          />
          <TicketsQueueSelect
            style={{ marginLeft: 6 }}
            selectedQueueIds={selectedQueueIds}
            userQueues={user?.queues}
            onChange={(values) => setSelectedQueueIds(values)}
          />
        </DialogContent>
      </Dialog>
      <Paper square elevation={0} className={classes.ticketOptionsBox}>
        <CustomToolTip title={i18n.t("ticketsList.assignedHeaderTip")} >
          <div className={`${classes.tabIcon} ${classes.tabIconSelect} ${tabOpen === "open" ? classes.tabIconOpen : ""}`} onClick={(e) => { setTab("open"); setTabOpen("open") }} >
            <Badge
              overlap="rectangular"
              className={classes.badge}
              badgeContent={openCount}
              color="primary"
            >
              {i18n.t("ticketsList.assignedHeader")}
            </Badge>
            <ArrowDropDown fontSize="large" className={`${classes.tabIconArrowHidden} ${tabOpen === "open" ? classes.tabIconOpenArrow : ""}`} />
          </div>
        </CustomToolTip>
        <CustomToolTip title={i18n.t("ticketsList.pendingHeaderTip")} >
          <div className={`${classes.tabIcon} ${classes.tabIconSelect} ${tabOpen === "pending" ? classes.tabIconPending : ""}`} onClick={(e) => { setTab("open"); setTabOpen("pending") }} >
            <Badge
              overlap="rectangular"
              className={classes.badge}
              badgeContent={pendingCount}
              color="secondary"
            >
              {i18n.t("ticketsList.pendingHeader")}
            </Badge>
            <ArrowDropDown fontSize="large" className={`${classes.tabIconArrowHidden} ${tabOpen === "pending" ? classes.tabIconPendingArrow : ""}`} />
          </div>
        </CustomToolTip>
        <CustomToolTip title={i18n.t("ticketsList.closedHeaderTip")} >
          <div className={`${classes.tabIcon} ${classes.tabIconSelect} ${tabOpen === "closed" ? classes.tabIconClosed : ""}`} onClick={(e) => { setTab("open"); setTabOpen("closed") }} >
            <Badge
              overlap="rectangular"
              className={classes.badge}
              badgeContent={0}
              color="secondary"
            >
              {i18n.t("tickets.tabs.closed.title")}
            </Badge>
            <ArrowDropDown fontSize="large" className={`${classes.tabIconArrowHidden} ${tabOpen === "closed" ? classes.tabIconClosedArrow : ""}`} />
          </div>
        </CustomToolTip>
        <CustomToolTip title={i18n.t("ticketsList.searchHeaderTip")} >
          <div className={`${classes.tabIcon} ${tab === "search" ? classes.tabIconSearch : ""}`} >
            <Search
              onClick={(e) => { setTab("search"); setTabOpen("search") }}
            />
            <ArrowDropDown fontSize="large" className={`${classes.tabIconArrowHidden} ${tabOpen === "search" ? classes.tabIconSearchArrow : ""}`} />
          </div>
        </CustomToolTip>
        <CustomToolTip title={`${i18n.t("global.filter")}s`} >
          <FilterList
            className={classes.tabIcon}
            onClick={handleOpenOptions}
          />
        </CustomToolTip>
        <CustomToolTip title={i18n.t("ticketsManager.buttons.newTicket")} >
          <AddCircle
            onClick={(e) => setNewTicketModalOpen(true)}
            className={classes.tabIcon}
          />
        </CustomToolTip>
      </Paper>
      <NewTicketModal
        modalOpen={newTicketModalOpen}
        onClose={(e) => setNewTicketModalOpen(false)}
      />
      <TabPanel value={tab} name="open"
        className={`
          ${classes.ticketsList}
          ${tabOpen === "open" ? classes.ticketListOpen : ""}
          ${tabOpen === "pending" ? classes.ticketListPending : ""}
          ${tabOpen === "closed" ? classes.ticketListClosed : ""}
        `}
      >
        <TicketsList
          onSetTabOpen={handleChangeTabOpenChild}
          status="open"
          showAll={showAllTickets}
          users={selectedUsers}
          selectedQueueIds={selectedQueueIds}
          updateCount={(val) => setOpenCount(val)}
          style={applyPanelStyle("open")}
        />
        <TicketsList
          onSetTabOpen={handleChangeTabOpenChild}
          status="pending"
          selectedQueueIds={selectedQueueIds}
          users={selectedUsers}
          updateCount={(val) => setPendingCount(val)}
          style={applyPanelStyle("pending")}
        />
        <TicketsList
          onSetTabOpen={handleChangeTabOpenChild}
          status="closed"
          showAll={true}
          users={selectedUsers}
          selectedQueueIds={selectedQueueIds}
          style={applyPanelStyle("closed")}
        />

      </TabPanel>
      <TabPanel value={tab} name="search" className={`
          ${classes.ticketsWrapper}
          ${tabOpen === "search" ? classes.ticketListSearch : ""}
        `} >
        <div className={classes.serachInputWrapper}>
          <Search className={classes.search} />
          <InputBase
            autoFocus
            className={classes.searchInput}
            inputRef={searchInputRef}
            placeholder={i18n.t("tickets.search.placeholder")}
            type="search"
            onChange={handleSearch}
          />
        </div>

        <Typography className={classes.searchTitle} variant="h6" gutterBottom>
          <FilterList /> {i18n.t("ticketsList.searchResult")}
        </Typography>

        <TicketsList
          isSearch={true}
          onSetTabOpen={handleChangeTabOpenChild}
          searchParam={searchParam}
          showAll={true}
          users={selectedUsers}
          selectedQueueIds={selectedQueueIds}
        />

      </TabPanel>

    </Paper>
  );
};

export default TicketsManager;
