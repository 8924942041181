import React, { useEffect, useState, useContext } from "react";
import axios from "axios";

import {
  Button,
  CssBaseline,
  TextField,
  Box,
  Typography,
  Container,
  InputAdornment,
  IconButton,
  Link,
  Grid,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
} from '@material-ui/core';


import { Alert, AlertTitle } from "@material-ui/lab";

import { Visibility, VisibilityOff } from '@material-ui/icons';
import { makeStyles } from "@material-ui/core/styles";
import { i18n } from "../../translate/i18n";

import { AuthContext } from "../../context/Auth/AuthContext";
import api from "../../services/api";

import packageJson from '../../../package.json';

import logoBlack from '../../assets/logo-send4whats-black.png';
import logoWhite from '../../assets/logo-send4whats-white.png';
import HexToRgb from "../../components/HexToRgb";

const Copyright = () => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      © {new Date().getFullYear()}
      {" - "}
      <Link color="inherit" href={packageJson.systemVars.support.link} >
        {packageJson.systemVars.appName} - v{packageJson.systemVars.version}
      </Link>
      {"."}
    </Typography>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    paddind: 10,

  },
  gridItem: {
    borderRight: "1px solid silver",
    padding: "20px"
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  img: {
    margin: theme.spacing(1),
    marginBottom: "30px",
    paddingBottom: "30px",
    width: "250px",
    borderBottom: "1px solid #cecece",
  },
}));

const Login = ({ colorTheme }) => {
  const classes = useStyles();

  const [user, setUser] = useState({ email: "", password: "", code: "" });
  const [showPassword, setShowPassword] = useState(false);
  const [posts, setPosts] = useState();
  const [accessCode, setAccessCode] = useState(true);

  const { handleLogin, handleValidateEmail, isAccessCode } = useContext(AuthContext);

  const handleChangeInput = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    const fetchList = async () => {
      try {
        const { data } = await api.get("/settings/getAccessCode");
        if (data === "disabled") { setAccessCode(false); }
      } catch (err) {
      }
    };
    fetchList();
  }, [accessCode]);


  const handlSubmitEmail = async (e) => {
    e.preventDefault();
    await handleValidateEmail(user);
  };

  const handlSubmit = (e) => {
    e.preventDefault();
    handleLogin(user);
  };

  useEffect(() => {
    let url = `https://send4whats.com/wp-json/wp/v2/posts`;

    axios.get(url).then((res) => {
      const { data } = res;
      setPosts(data);
    });
  }, []);


  return (
    <div className={classes.root}>
      <Grid container >
        <Grid item lg={6} xs={6} style={{ borderRight: "1px solid silver" }}
          component={Box}
          className={classes.gridItem}
          display={{ xs: "none", lg: "block" }}
        >
          {posts &&
            <Card className={classes.card}>
              <CardActionArea>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    {posts[0].title.rendered}
                  </Typography>
                  <p dangerouslySetInnerHTML={{ __html: posts[0].content.rendered }}></p>
                </CardContent>
              </CardActionArea>
              <CardActions>
                <Link color="inherit" href={`${posts[0].link}`}>
                  <Button size="small" color="primary">
                    Leia no site...
                  </Button>
                </Link>
                <Link color="inherit" href={`${packageJson.systemVars.support.link}/blog`}>
                  <Button size="small" color="primary">
                    Outros informativos...
                  </Button>
                </Link>

              </CardActions>
            </Card>
          }

        </Grid>
        <Grid item lg={6} xs={12}>
          <Container component="main" maxWidth="xs" className={classes.paper}>
            <CssBaseline />

            {HexToRgb(colorTheme) === "#000000" ? (
              <img className={classes.img} src={logoWhite} alt="Logo" />
            ) : (
              <img className={classes.img} src={logoBlack} alt="Logo" />
            )}

            {/* EMAIL */}
            {!isAccessCode &&
              <form className={classes.form} noValidate onSubmit={accessCode ? handlSubmitEmail : handlSubmit}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label={i18n.t("login.form.email")}
                  name="email"
                  value={user.email}
                  onChange={handleChangeInput}
                  autoComplete="email"
                  autoFocus
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label={i18n.t("login.form.password")}
                  id="password"
                  value={user.password}
                  onChange={handleChangeInput}
                  autoComplete="current-password"
                  type={showPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword((e) => !e)}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  {i18n.t("login.buttons.submit")}
                </Button>
              </form>
            }
            {isAccessCode &&
              <form className={classes.form} noValidate onSubmit={handlSubmit}>
                <Alert severity="info">
                  <AlertTitle>{i18n.t("login.form.accessCode")}</AlertTitle>
                  {i18n.t("login.textAccessCode")} [{user.email}]
                </Alert>
                <TextField
                  variant="filled"
                  required
                  fullWidth
                  id="accessCode"
                  label={i18n.t("login.form.accessCode")}
                  name="accessCode"
                  value={user.accessCode}
                  onChange={handleChangeInput}
                  autoComplete="accessCode"
                  autoFocus
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  {i18n.t("login.buttons.submit")}
                </Button>

              </form>
            }
            <Box mt={8}><Copyright /></Box>
          </Container>
        </Grid>
      </Grid>
    </div>
  );
};

export default Login;
