import { Chip } from "@material-ui/core";
import React, { useEffect, useState, useContext } from "react";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { AuthContext } from "../../context/Auth/AuthContext";

import { makeStyles } from "@material-ui/core/styles";

import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";


import { i18n } from "../../translate/i18n";

const useStyles = makeStyles(theme => ({
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
}));



export function UsersFilter({ onFiltered, selectedUsers = [], initialUsers, onChange }) {
  const [users, setUsers] = useState([]);

  const { user } = useContext(AuthContext);
  const { profile } = user;

  useEffect(() => {
    async function fetchData() {
      await loadUsers();
    }
    fetchData();
    // eslint-disable-next-line
  }, []);


  const loadUsers = async () => {
    try {
      const { data } = await api.get(`/users/list`);

      let userList = data;
      if (profile.toUpperCase() !== "SUPER") {

        userList = userList.filter((t) => t.profile.toUpperCase() !== 'SUPER');
      }
      userList = userList.map((u) => ({ id: u.id, name: u.name }));

      setUsers(userList);
    } catch (err) {
      toastError(err);
    }
  };


  const classes = useStyles();
  const handleChange = e => {
    onChange(e.target.value);
  };

  return (
    <div style={{ flex: 1, border: "none" }}>
      <form>
        <FormControl fullWidth margin="dense" variant="outlined">
          <InputLabel>{i18n.t("global.user")}s</InputLabel>
          <Select
            multiple
            labelWidth={60}
            value={selectedUsers}
            onChange={handleChange}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}
            renderValue={selected => (
              <div className={classes.chips}>
                {selected?.length > 0 &&
                  selected.map(id => {
                    const user = users.find(q => q.id === id);
                    return user ? (
                      <Chip
                        key={id}
                        variant="outlined"
                        label={user.name}
                        size="small"
                        className={classes.chip}
                      />
                    ) : null;
                  })}
              </div>
            )}
          >
            {users.map(user => (
              <MenuItem key={user.id} value={user.id}>
                {user.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </form>


    </div>
  );
}
